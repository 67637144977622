import { Profiler, useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import PopUp from "components/web/popup";

import { Context } from "context/data";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";
// srcTopBar: Arrow,

export default (param) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [errors, setErrors] = useState({});

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 8,
      fontSize: "1vw",
      maxWidth: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
  };

  // useEffect(async () => {
  //   // const filterCity = await api.utils.city_name({
  //   //   query: {
  //   //     id: selectedState,
  //   //   },
  //   // });
  //   // setCityList(filterCity.data.data);
  //   console.log("lista de cidade", cityList);
  // }, [selectedState]);

  const getErrorMessage = (fieldId, errors) => {
    if (Array.isArray(errors)) {
      const error = errors.find(
        (err) =>
          err.params.missingProperty === fieldId || err.params.type === fieldId
      );
      return error ? `O campo ${fieldId} é obrigatório` : "";
    }
    return "";
  };

  const removeError = (field) => {
    setErrors((prevErrors) =>
      Array.isArray(prevErrors)
        ? prevErrors.filter(
            (err) =>
              err.params.missingProperty !== field && err.params.type !== field
          )
        : []
    );
  };

  const handleSubmit = async (formData) => {
    try {
      // Resetando os erros antes do envio
      setErrors({});

      // Validação inicial
      const requiredFields = [
        "name",
        "message",
        "filter",
        // "situation",
        // "state",
        // "city",
      ];
      const missingFields = requiredFields.filter((field) => !formData[field]);
      if (missingFields.length > 0) {
        const validationErrors = missingFields.map((field) => ({
          params: { missingProperty: field },
          message: `O campo ${field} é obrigatório.`,
        }));
        setErrors(validationErrors);
        return;
      }

      // Chamando a API para salvar os dados
      const response = await api.marketing.notify(formData);

      if (response?.data?.status) {
        // Exibindo popup de sucesso
        PopupShow({
          title: "Pushup criado com sucesso!",
          description: "",
          confirmText: "Voltar para a lista",
          onConfirm: () => {
            // Lógica para limpar e voltar
            setSelectedState("");
            setCityList([]);
            PopupClose();
            GoPage("marketing");
          },
        });
      } else {
        // Erro da API
        PopupShow({
          title: "Erro ao criar pushup",
          description: response?.data?.message || "Ocorreu um erro inesperado.",
          confirmText: "Ok",
        });
      }
    } catch (error) {
      // Tratando erros genéricos
      PopupShow({
        title: "Erro de conexão",
        description: "Verifique sua conexão e tente novamente.",
        confirmText: "Ok",
      });
    }
  };

  //Adicionar parceiros
  return {
    component: "container",
    style: {
      display: PageInfo.show === "pushups-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Criação de novo pushup",
        srcTopBar: Arrow,
        // formData: PageInfo.param.info,
        form: {
          onSave: handleSubmit,
          // onCancel: () => {},
          // func: (data, info, clear, goBack) => {
          //   const error = data.error;
          //   if (error) {
          //     // PopupShow({
          //     //   title: "Campos não preenchidos",
          //     //   description: "Por favor, revise e complete os dados",
          //     //   style: {
          //     //     close: {
          //     //       display: "none",
          //     //     },
          //     //   },
          //     //   cancelText: "",
          //     //   confirmText: "Ok",
          //     //   onCancel: async () => {},
          //     //   onConfirm: async () => {},
          //     // });
          //     setErrors(error);
          //   }
          //   if (data.data?.status == true) {
          //     PopupShow({
          //       title: "Parceiro editado com sucesso!",
          //       description: "",
          //       cancelText: "",
          //       confirmText: "ir para lista",
          //       onCancel: async () => {},
          //       onConfirm: async () => {
          //         goBack();
          //         clear();
          //       },
          //     });
          //   }
          //   // else {
          //   //   PopupShow({
          //   //     title: "Edição não permitida!",
          //   //     description: "",
          //   //     cancelText: "",
          //   //     style: {
          //   //       close: {
          //   //         display: "none",
          //   //       },
          //   //     },
          //   //     confirmText: "voltar para lista",
          //   //     onCancel: async () => {},
          //   //     onConfirm: async () => {
          //   //       goBack();
          //   //       clear();
          //   //     },
          //   //   });
          //   // }
          // },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "50%",
              maxHeight: "72%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              margin: "1%",
              padding: "1%",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "51%",
              },
            },
          },
          box: {
            //Informações pessoais
            1: {
              style: {
                flex: 1,
                minWidth: "100%",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações Gerais",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 12,
                    height: 16,
                  },
                },
                //Nome
                {
                  id: "name",
                  label: "Nome de identificação",
                  type: "input",
                  errorMessage: getErrorMessage("name", errors),
                  set: () => removeError("name"),
                  style: {
                    input: {
                      border: "1px solid #bfbfbf",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: "1vw",
                      maxWidth: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontWeight: "600",
                      border:
                        Array.isArray(errors) &&
                        errors.some(
                          (err) => err.params.missingProperty === "name"
                        )
                          ? "1px solid red"
                          : "1px solid #bfbfbf",
                    },
                  },
                  required: true,
                  // check: { min: 5 },
                },
                //Message
                {
                  id: "message",
                  label: "Mensagem",
                  set: () => removeError("message"),
                  type: "input",
                  maxLength: 160,
                  required: true,
                  errorMessage: getErrorMessage("message", errors),
                  style: {
                    input: {
                      border: "1px solid #bfbfbf",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: "1vw",
                      width: "100%",
                      height: "75px",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontWeight: "600",
                      border:
                        Array.isArray(errors) &&
                        errors.some(
                          (err) => err.params.missingProperty === "message"
                        )
                          ? "1px solid red"
                          : "1px solid #bfbfbf",
                    },
                  },
                },
              ],
            },
            //Filtros
            2: {
              style: {
                flex: 1,
                minWidth: "100%",
                display: "flex",
                // maxHeight: 128,
                flexDirection: "column",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                //label
                {
                  label: "Filtros",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 12,
                    height: 16,
                  },
                },
                //div
                {
                  type: "div",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                  },
                  children: [
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                      },
                      children: [
                        //por pet
                        {
                          id: "pet",
                          keyid: "filter.animal_category",
                          label: "Por tipo de pet",
                          type: "select",
                          option: api.utils.animal_category,
                          errorMessage: getErrorMessage("filter", errors),
                          set: () => removeError("filter"),
                          style: {
                            ...inputStyle,
                            width: "300px",
                            select: {
                              border:
                                Array.isArray(errors) &&
                                errors.some(
                                  (err) =>
                                    err.params.missingProperty === "filter"
                                )
                                  ? "1px solid red"
                                  : "1px solid #bfbfbf",
                            },
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                        //por situação
                        {
                          id: "situation",
                          keyid: "filter.situation",
                          label: "Por situação",
                          type: "select",
                          option: api.utils.marketing_situation,
                          style: {
                            ...inputStyle,
                            width: "300px",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                      },
                      children: [
                        //por estado
                        {
                          id: "state",
                          keyid: "filter.address_state",
                          label: "Por estado",
                          option: api.utils.state_name,
                          type: "select",
                          style: {
                            ...inputStyle,
                            width: "300px",
                          },
                          required: true,
                          func: async (e) => {
                            // console.log("ver estado", e);
                            setSelectedState(e._id);
                            const filterCity = await api.utils.city_name({
                              query: {
                                id: e._id,
                              },
                            });
                            setCityList(filterCity.data.data);
                          },
                          //check: { min: 5 },
                        },
                        //por cidade
                        {
                          id: "city",
                          keyid: "filter.address_city",
                          label: "Por cidade",
                          option: cityList,
                          update: cityList,
                          type: "select",
                          style: {
                            ...inputStyle,
                            width: "300px",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
        showPopup: false,
      }),
    ],
  };
};
