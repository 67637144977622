import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import { Context } from "context/data";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Howl, Howler } from "howler";

import Theme from "theme";

import api from "service";

//images
import ImagePerson from "assets/images/bcg/petFriendly.png";
import Logo from "assets/images/camada_1.png";

//components
import Access from "components/web/access";

export default (props) => {
  const { Data, Set } = Context();

  useEffect(async () => {}, []);

  return {
    render: (
      <div style={{ display: !Data.user?.status ? "flex" : "none" }}>
        <Fade>
          {Render(
            Access({
              title: "Painel de controle",
            })
          )}
        </Fade>
      </div>
    ),
  };
};
