import { useState } from "react";
import Theme from "theme";

export default (props) => {
  return {
    component: "button",
    style: {
      ...Theme.button?.default?.box,
      cursor: "pointer",
      transition: "transform 0.1s ease",
      ...props?.style?.button,
      border: "none",
    },
    onClick: async () => {
      if (props?.onPress)
        try {
          await props.onPress(props);
        } catch (err) {
          console.log("button err", err);
        }

      // set Loading off
    },
    disabled: props?.disabled,
    children: [
      // Se houver filhos passados, mapeie-os
      ...(props?.children ? props.children : []),

      {
        component: "container",
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        },
        children: [
          // Renderiza o ícone se houver
          {
            render: props?.icon && (
              <img
                style={{
                  width: 24,
                  height: 24,
                  aspectRatio: "1/1",
                  transition: "transform 0.1s ease",
                  ...props?.style?.image,
                }}
                src={props.icon}
                alt="button icon"
              />
            ),
          },
          // Renderiza o texto do botão
          {
            component: "text",
            text: props?.label || "",
            style: {
              ...Theme.button?.default?.label,
              ...props?.style?.label,
            },
          },
        ],
      },
    ],
  };
};
