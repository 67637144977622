import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

import { ShowPage } from "context/showPage";

import { Context } from "context/data";

//components
import getElement from "components/web/view/getElement";

//images
import Logo from "assets/images/logo.png";
import DogWoman from "assets/images/iconDogEpeople.svg";
import ShieldCheck from "assets/images/ShieldCheck.svg";

//pages
import Login from "./login/login";
import Recovery from "./recovery/recovery";
import EmailSent from "./emailSent/emailSent";
import ResetPassword from "./resetPassword/resetPassword";
import loading from "../loading";

export default (props) => {
  const [content, setContent] = useState("Login");
  const [userEmail, setUserEmail] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  const { Data, Set } = Context();

  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  useEffect(async () => {
    if (PageInfo.show === "ResetPassword") {
      setContent("ResetPassword");
    }
  }, [PageInfo]);

  // useEffect(() => {
  //   if (Data.user?.status) {
  //     alert("oi");
  //   }
  // }, [Data.user]);

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: "flex",
            width: "100vw",
            height: "100vh",
            flexDirection: "row",
            backgroundColor: "#F4F4F4",
          },
          children: [
            // loadingSave && loading(),
            //card a esquerda
            {
              component: "container",
              style: {
                display: "flex",
                width: "32vw",
                minWidth: 504,
                height: "100%",
                backgroundColor: "#05332F",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-btween",
              },
              children: [
                //cardTopInfo
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    height: "14vh",
                    flexDirection: "column",
                    padding: 22,
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  },
                  children: [
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: "100%",
                        height: "50%",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 4,
                      },
                      children: [
                        //iconSild
                        {
                          component: "image",
                          src: ShieldCheck,
                          style: {
                            width: 24,
                            height: 24,
                          },
                        },
                        //text
                        {
                          component: "text",
                          text: "AMBIENTE SEGURO",
                          style: {
                            fontSize: 18,
                            fontFamily: "Poppins",
                            color: "rgba(0, 166, 96, 1)",
                          },
                        },
                      ],
                    },
                    //title
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        height: "50%",
                        flexDirection: "row",
                      },
                      children: [
                        {
                          component: "text",
                          text: props?.title || "Painel de controle",
                          style: {
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: 32,
                            color: "rgba(255, 245, 221, 1)",
                          },
                        },
                      ],
                    },
                  ],
                },
                //cardImage
                {
                  component: "container",
                  style: {
                    position: "absolute",
                    bottom: 0,
                    display: "flex",
                    width: "44%",
                    height: "72%",
                    alignItems: "flex-end",
                  },
                  children: [
                    {
                      component: "image",
                      src: DogWoman,
                      style: {
                        width: "100%",
                        minWidth: 728,
                        height: "80%",
                        aspectRatio: "1/1",
                      },
                    },
                  ],
                },
              ],
            },
            //card a direita
            {
              component: "container",
              style: {
                display: "flex",
                width: "68vw",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              },
              children: [
                //content
                {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "50%",
                    height: "80%",
                  },
                  children: [
                    //logoPetin
                    {
                      component: "image",
                      src: Logo,
                      style: {
                        width: 128,
                        height: "auto",
                        cursor: "pointer",
                      },
                      onClick: () => {
                        setContent(
                        );
                      },
                    },
                    //contentForm (login/recovery)
                    Login({
                      content,
                      setContent,
                      setLoadingSave,
                    }),
                    Recovery({
                      content,
                      setContent,
                      setLoadingSave,
                    }),
                    ResetPassword({content,
                      setContent,
                      setLoadingSave,})
                  ],
                },
              ],
            },
          ],
        })}
      </Fade>

    ),
  };
};
