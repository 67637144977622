import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import Button from "components/web/button";

//images
import EditManagement from "assets/images/edit-management.png";
import DeleteManagement from "assets/images/icon-delete.png";

//pages

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "access-level" ? "flex" : "none",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "access-level",
            title: "Níveis de acesso",
            buttonTopBar: {
              style: {
                button: {},
                label: {},
              },
              title: "Adicionar novo nível",
              onPress: () => {
                GoPage("accesslevel-add");
              },
            },
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontWeight: "normal",
                      // flex: 1.2,
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 64,
                maxHeight: 64,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Nome",
                style: {
                  flex: "0.5",
                },
              },
              user_access: {
                key: [],
                title: "Usuários",
              },
              order_access: {
                key: [],
                title: "Pedidos",
                style: {},
              },
              business_access: {
                key: [],
                title: "Empresas",
              },
              partner_access: {
                key: [],
                title: "Parceiros",
              },
              financial_access: {
                key: [],
                title: "Financeiro",
              },
              marketing_access: {
                key: [],
                title: "Marketing",
              },
              users_number: {
                key: [],
                title: "N.° de usuários",
              },

              edit: {
                title: "Ações",
                style: {
                  flex: "0",
                },
                item: [
                  {
                    type: "image",
                    src: EditManagement,
                    size: 28,
                    style: {
                      display: "flex",
                      border: "none",
                      backgroundColor: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    },
                    onClick: async (info) => {
                      GoPage("accesslevel-edit", { info: info });
                    },
                  },
                  {
                    type: "image",
                    src: DeleteManagement,
                    size: 28,
                    style: {
                      display: "flex",
                      border: "none",
                      backgroundColor: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    },
                    onClick: async (info) => {
                      PopupShow({
                        title: `Deseja excluir o nivel de acesso ${info.name}?`,
                        description: "",
                        cancelText: "cancelar",
                        confirmText: "deletar",
                        onCancel: async () => {},
                        onConfirm: async () => {
                          await api.management.delete_access({ id: info._id });
                          setUpdate("access-level");
                        },
                      });
                    },
                  },
                ],
              },
            },
            item: api.management.list_profile,
          }),
        ],
      },
    ],
  };
};
