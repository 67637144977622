import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import CalendarView from "components/web/suggestHour";
import Button from "components/web/button";

//images
import Close from "assets/images/menu/close.png";
import Arrow from "assets/images/menu/arrowBack.svg";
// srcTopBar: Arrow,

//pages
import SugestHour from "./sugestHour";

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "order-buy" ? "flex" : "none",
    },
    children: [
      SugestHour(popUp, setPopUp, item, selectedHours, setSelectedHours),
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "order-buy",
            title: "Lista de pedidos",
            //filtros
            filter: [
              //novos pedidos
              {
                id: "NewOrder",
                type: "button",
                label: "Novos pedidos",
                query: "new",
                // count: ,
                style: {
                  box: {},
                },
              },
              //pedidos em andamento
              {
                id: "OrderProgress",
                type: "button",
                label: "Em andamento",
                query: "waiting",
                style: {
                  box: {},
                },
              },
              //pedidos finalizados
              {
                id: "OrderFinish",
                type: "button",
                label: "Finalizados",
                query: "finish",
                style: {
                  box: {},
                },
              },
            ],
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "provider":
                    return {
                      display: "flex",
                      color: Theme.colors.secondary,
                      fontSize: Theme.fontSizes.row,
                      cursor: "pointer",
                    };
                  case "people":
                    return {
                      display: "flex",
                      color: Theme.colors.secondary,
                      fontSize: Theme.fontSizes.row,
                      cursor: "pointer",
                    };
                  case "services":
                    return {
                      display: "flex",
                      color: Theme.colors.secondary,
                      fontSize: Theme.fontSizes.row,
                      cursor: "pointer",
                      flex: 1,
                      alignItems: "center",
                      flexDirection: "center",
                      textAlign: "flex-start",
                    };
                  case "status":
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                      borderRadius: 4,
                      padding: 2,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item[props.col].code === 0
                          ? "rgba(229, 46, 46, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(5, 150, 105, 1)"
                          : props.item[props.col].code >= 2
                          ? "rgba(75, 85, 99, 1)"
                          : "rgba(75, 85, 99, 1)",
                      backgroundColor:
                        props.item[props.col].code === 0
                          ? "rgba(229, 46, 46, 0.5)"
                          : props.item[props.col].code === 1
                          ? "rgba(167, 243, 208, 1)"
                          : props.item[props.col].code >= 2
                          ? "rgba(75, 85, 99, 0.5)"
                          : "rgba(75, 85, 99, 0.5)",
                    };
                  default:
                    return {
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              id: {
                title: "ID",
                condition: (id) => {
                  return "# " + id.id;
                },
                style: {},
              },
              provider: {
                key: ["provider", "nickname"],
                title: "Parceiro",
                style: {
                  flex: 1.5,
                },
                onClick: async (info) => {
                  PopupShow({
                    title: "",
                    description: "",
                    confirmText: "",
                    onConfirm: async () => {},
                    onClose: () => {},
                    style: {
                      box: {
                        width: "auto",
                        alignItems: "center",
                      },
                      close: {
                        display: "none",
                      },
                    },
                    item: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          height: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 8,
                          gap: 8,
                        },
                        children: [
                          //Parceiro
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              //titleParceiro
                              {
                                component: "text",
                                text: "Parceiro: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              //parceiroNickname
                              {
                                component: "text",
                                text: info?.provider?.nickname,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Responsável
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              //titleParceiro
                              {
                                component: "text",
                                text: "Responsável: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              //responsavelNickname
                              {
                                component: "text",
                                text: info?.business?.nickname,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Telefone
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              //titleTelefone
                              {
                                component: "text",
                                text: "Telefone: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              //Telefone
                              {
                                component: "text",
                                text: info?.provider?.whatsapp,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Endereço
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              //titleEndereço
                              {
                                component: "text",
                                text: "Endereço: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              //rua do endereço
                              {
                                component: "text",
                                text: info?.provider?.address.street,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                              //Numero do endereço
                              {
                                component: "text",
                                text: info?.provider?.address.number + ",",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                              //Cidade do endereço
                              {
                                component: "text",
                                text: info?.provider?.address.city + " -",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                              //Cidade do endereço
                              {
                                component: "text",
                                text: info?.provider?.address.state,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    buttons: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 12,
                        },
                        children: [
                          //btn fechar
                          Button({
                            label: "Fechar",
                            style: {
                              button: {
                                backgroundColor: "white",
                                width: "100%",
                                alignItems: "center",
                                height: "42%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                              },
                              label: {
                                fontFamily: Theme.fontFamilies.primary,
                                fontSize: 18,
                              },
                            },
                            onPress: async () => {
                              PopupClose();
                            },
                          }),
                        ],
                      },
                    ],
                  });
                },
              },
              people: {
                title: "Cliente",
                key: ["people", "name"],
                style: {
                  flex: 1.5,
                },
                onClick: async (info) => {
                  PopupShow({
                    title: "",
                    description: "",
                    confirmText: "",
                    onConfirm: async () => {},
                    onClose: () => {},
                    style: {
                      box: {
                        width: "auto",
                        alignItems: "center",
                      },
                      close: {
                        display: "none",
                      },
                    },
                    item: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          height: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 8,
                          gap: 8,
                        },
                        children: [
                          //Nome cliente
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Nome: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.people?.name || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //E-mail
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "E-mail: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.people?.contacts[0]?.email || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Creditos
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Créditos: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: "R$ " + info?.people?.credit,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Registro
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Registro: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.people?.date,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //ultimo acesso
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Último acesso: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.people?.last_access,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Fonte
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Fonte: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.people?.business?.nickname,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Pet
                          {
                            component: "text",
                            text: "Pet",
                            style: {
                              width: "100%",
                              fontSize: 16,
                              fontWeight: "600",
                              fontFamily: "Poppins",
                              marginTop: 12,
                            },
                          },
                          //container pet
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 8,
                              alignItems: "center",
                              justifyContent: "flex-start",
                              padding: 4,
                              paddingRight: 32,
                              borderRadius: 8,
                              border: "1px solid gray",
                              boxShadow: "1px 1px 10px rgba(211, 211, 211, 1)",
                            },
                            children: [
                              //Image
                              {
                                component: "image",
                                style: {
                                  width: 64,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: Theme.colors.gray.background,
                                  borderRadius: 8,
                                  padding: 4,
                                },
                                src: info.pet?.picture_thumb,
                              },
                              //info
                              {
                                component: "container",
                                style: {
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  flexDirection: "column",
                                  gap: 8,
                                },
                                children: [
                                  //name
                                  {
                                    component: "text",
                                    text: info.pet?.name || "",
                                    style: {
                                      width: "100%",
                                      textAlign: "flex-start",
                                      fontFamily: "Poppins",
                                      fontWeight: "600",
                                      fontSize: 16,
                                    },
                                  },
                                  //info
                                  {
                                    component: "container",
                                    style: {
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      gap: 8,
                                    },
                                    children: [
                                      //raça
                                      {
                                        component: "text",
                                        text: info.pet?.breed.name || "",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //kg
                                      {
                                        component: "text",
                                        text: info.pet?.size.name || "",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //castração
                                      {
                                        component: "text",
                                        text: info.pet.neutered_animal
                                          ? "Castrado"
                                          : "Normal",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //type_animal
                                      {
                                        component: "text",
                                        text: info.pet.animal_category.name,
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    buttons: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 12,
                        },
                        children: [
                          //btn fechar
                          Button({
                            label: "Fechar",
                            style: {
                              button: {
                                backgroundColor: "white",
                                width: "100%",
                                alignItems: "center",
                                height: "42%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                              },
                              label: {
                                fontFamily: Theme.fontFamilies.primary,
                                fontSize: 18,
                              },
                            },
                            onPress: async () => {
                              PopupClose();
                            },
                          }),
                        ],
                      },
                    ],
                  });
                },
              },
              business: {
                key: ["people", "business", "nickname"],
                title: "Empresa",
                style: {},
              },
              services: {
                title: "Serviço",
                style: {},
                condition: (param) => {
                  const serviceNames = param.services.map(
                    (item) => item?.service?.category?.name
                  );
                  return serviceNames.length > 1
                    ? `${serviceNames[0]}...`
                    : serviceNames[0];
                },
                onClick: async (info) => {
                  PopupShow({
                    title: "",
                    description: "",
                    confirmText: "",
                    onConfirm: async () => {},
                    onClose: () => {},
                    style: {
                      box: {
                        width: "auto",
                        alignItems: "center",
                      },
                      close: {
                        display: "none",
                      },
                    },
                    item: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          height: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 8,
                          gap: 8,
                        },
                        children: [
                          //container pet
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 8,
                              alignItems: "center",
                              justifyContent: "flex-start",
                              padding: 4,
                              paddingRight: 32,
                              borderRadius: 8,
                              border: "1px solid gray",
                              boxShadow: "1px 1px 10px rgba(211, 211, 211, 1)",
                              marginBottom: 22,
                            },
                            children: [
                              //Image
                              {
                                component: "image",
                                style: {
                                  width: 64,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: Theme.colors.gray.background,
                                  borderRadius: 8,
                                  padding: 4,
                                },
                                src: info.pet?.picture_thumb,
                              },
                              //info
                              {
                                component: "container",
                                style: {
                                  display: "flex",
                                  width: "100%",
                                  height: "100%",
                                  flexDirection: "column",
                                  gap: 8,
                                },
                                children: [
                                  //name
                                  {
                                    component: "text",
                                    text: info.pet?.name || "",
                                    style: {
                                      width: "100%",
                                      textAlign: "flex-start",
                                      fontFamily: "Poppins",
                                      fontWeight: "600",
                                      fontSize: 16,
                                    },
                                  },
                                  //info
                                  {
                                    component: "container",
                                    style: {
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                      flexDirection: "row",
                                      gap: 8,
                                    },
                                    children: [
                                      //raça
                                      {
                                        component: "text",
                                        text: info.pet?.breed.name || "",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //kg
                                      {
                                        component: "text",
                                        text: info.pet?.size.name || "",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //castração
                                      {
                                        component: "text",
                                        text: info.pet.neutered_animal
                                          ? "Castrado"
                                          : "Normal",
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                      //•
                                      {
                                        component: "text",
                                        text: "•",
                                        style: {
                                          color: "gray",
                                        },
                                      },
                                      //type_animal
                                      {
                                        component: "text",
                                        text: info.pet.animal_category.name,
                                        style: {
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "400",
                                          color: "gray",
                                          fontSize: 12,
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          //Categoria
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Categoria: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text:
                                  info?.services[0]?.service?.category?.name ||
                                  "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Serviço
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Serviço: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text:
                                  info?.services[0]?.service?.sub_category
                                    ?.name || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Serviço Adicional
                          {
                            component: "container",
                            style: {
                              display:
                                info?.services.length > 1 ? "flex" : "none",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Serviço adicional: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.services[1]?.description || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Detalhes
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Detalhes: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    buttons: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 12,
                        },
                        children: [
                          //btn fechar
                          Button({
                            label: "Fechar",
                            style: {
                              button: {
                                backgroundColor: "white",
                                width: "100%",
                                alignItems: "center",
                                height: "42%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                              },
                              label: {
                                fontFamily: Theme.fontFamilies.primary,
                                fontSize: 18,
                              },
                            },
                            onPress: async () => {
                              PopupClose();
                            },
                          }),
                        ],
                      },
                    ],
                  });
                },
              },
              date: {
                // key: [],
                title: "Data de envio",
                style: {
                  flex: 1.5,
                },
              },
              service_date: {
                title: "Agendamento",
                style: {
                  flex: 1.5,
                },
              },
              service_value: {
                title: "Valor total",
                condition: (param) => {
                  return "R$ " + param?.service_value;
                },
                style: {},
              },
              status: {
                title: "Status",
                style: {
                  flex: 1.8,
                },
                condition: (param) => {
                  return param.status.name;
                },
              },
              situation: {
                title: "Ações",
                item: [
                  {
                    value: ({ row }) => {
                      return {
                        component: "container",
                        style: {
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        children: [
                          {
                            render: (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  value={selectState}
                                  onChange={async (item) => {
                                    // console.log("verSelect", row, item);
                                    //confirmar
                                    if (item.target.value === "1") {
                                      PopupShow({
                                        title:
                                          "Deseja confirmar " + row.id + " ?",
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        onConfirm: async () => {
                                          const result =
                                            await api.order.confirm({
                                              order: row._id,
                                            });
                                          setSelectState(-1);
                                          setUpdate("order-buy" + new Date());
                                          GoPage("order-buy");
                                        },
                                        onCancel: async () => {
                                          setUpdate("order-buy" + new Date());
                                          setSelectState(-1);
                                        },
                                        item: [],
                                      });
                                    }
                                    //cancelar
                                    else if (item.target.value === "2") {
                                      // setPopUp(true);
                                      PopupShow({
                                        title:
                                          "Deseja cancelar " + row.id + "?",
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        onConfirm: async () => {
                                          const result = await api.order.cancel(
                                            {
                                              order: row._id,
                                            }
                                          );
                                          setSelectState(-1);
                                          setUpdate("order-buy" + new Date());
                                          GoPage("order-buy");
                                        },
                                        onCancel: async () => {
                                          setSelectState(-1);
                                          setUpdate("order-buy" + new Date());
                                        },
                                        item: [],
                                      });
                                    }
                                    //Novo horário
                                    else if (item.target.value === "3") {
                                      setPopUp(true);
                                      setItem(row);
                                    }
                                  }}
                                  style={{
                                    width: "auto",
                                    display:
                                      row.status.code > 1 ? "flex" : "none",
                                    height: 26,
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "#FFBC11",
                                    backgroundColor: "#FFE6A7",
                                    borderRadius: 5,
                                  }}
                                >
                                  <option value={-1} disabled selected>
                                    -
                                  </option>
                                  {row.status?.code === 998 && (
                                    [
                                      "Confirmar",
                                      "Cancelar",
                                      "Ajuste de horário",
                                    ].map((text, value) => {
                                      return (
                                        <option value={value + 1}>
                                          {text}
                                        </option>
                                      );
                                    })
                                  )}
                                </select>
                              </div>
                            ),
                          },
                        ],
                      };
                    },
                  },
                ],
              },
            },
            item: api.order.list,
          }),
        ],
      },
    ],
  };
};
