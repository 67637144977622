import React, { useState, useEffect } from "react";
import Theme from "theme";
import getElement from "components/web/view/getElement";
import { Render } from "@8_dev/sjson-web";
import Fade from "react-reveal/Fade";
import { OneKkSharp } from "@mui/icons-material";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function Form(opt) {
  const [inputs, setInputs] = useState([]);

  useEffect(async () => {
    let inpts = [];
    let order = opt.props.value?.length - 1 || 0;

    if (opt?.props?.value && order >= 0) {
      opt.props.value.map((item, i) => {
        let inpItem = {};
        inputs.map((input) => {
          if (input?.id === (item.id || i)) {
            input.id = item.id || i;
            inpItem = input;
          }
        });
        if (inpItem?.id > 0) inpts[order - i] = inpItem;
        else if (item) inpts[order - i] = { id: i, value: item };
      });

      if (inputs !== inpts) setInputs(inpts);
    } else {
      // setInputs([{ id: 0, value: "" }]);
    }
  }, [opt.props.value]);

  const add = async () => {
    await sleep(250);
    setInputs([{ id: inputs.length, value: "" }, ...inputs]);
  };

  const remove = async (id) => {
    opt.props.delete(id);
  };

  const updateValue = (id, newValue) => {
    const newInputs = inputs.map((input) =>
      input.id === id ? { ...input, value: newValue } : input
    );
    setInputs(newInputs);
  };

  const submitForm = () => {
    // console.log("Form Data:", inputs);
  };

  return (
    <Fade top cascade>
      <Fade top>
        <button
          style={{
            ...Theme.button.default,
            ...opt.style?.button,
          }}
          type="button"
          onClick={() => add()}
        >
          Adicionar Campo
        </button>
      </Fade>
      <Fade>
        <div
          style={{
            ...Theme.box.default,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
            maxWidth: "100%",
            maxHeight: 350,
            overflow: "auto",
            ...opt.style?.list,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          {inputs.map((input, index) => {
            if (opt.props.value === undefined) return;
            return (
              <Fade top>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    maxWidth: "100%",
                    minWidth: "100%",
                    minHeight: "100%",
                    ...opt.style?.item,
                  }}
                >
                  {opt.children.map((child) => {
                    // if (opt.props.value === undefined) opt.props.value = [];

                    let childValue = opt?.props?.value?.filter(
                      (item) => item !== null && item !== undefined
                    );

                    if (childValue === undefined) childValue = [];

                    if (!childValue[input.id]) {
                      if (child.type === "box") {
                        childValue[input.id] = {
                          [child.id]: {},
                        };
                        childValue = childValue[input.id];
                      } else {
                        childValue[input.id] = "";
                        childValue = childValue[input.id];
                      }
                    } else {
                      childValue = childValue[input.id];

                      if (!childValue[child.id]) {
                        if (child.type === "box") {
                          childValue[child.id] = {};
                        } else {
                          childValue[child.id] = "";
                        }
                      }
                      childValue = childValue[child.id];
                    }

                    child.props = {
                      value: childValue,
                      set: opt.props.set,
                    };

                    child.keyid = opt.id + "." + input.id + "." + child.id;

                    return Render(getElement(child));
                  })}

                  <Fade>
                    <button
                      style={{
                        position: "absolute",
                        top: 4,
                        right: 8,
                      }}
                      type="button"
                      onClick={() => remove(input.id)}
                    >
                      -
                    </button>
                  </Fade>
                </div>
              </Fade>
            );
          })}
        </div>
      </Fade>
    </Fade>
  );
}

export default Form;
