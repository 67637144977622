// Init Page para valida algumas infoamções e selcionar para qual pagina direcionar..

// verificar historico, ou url com parametros..

import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import ListView from "components/web/view/list";
import PopUp from "components/web/popup";

import Theme from "theme";

import EditProvider from "assets/images/edit-user.png";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

export default (props) => {
  const { PageInfo, GoPage } = ShowPage();

  const [visible, setVisible] = useState({});

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  //Lista de parceiros
  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "provider-list" ? "flex" : "none",
      // backgroundColor: "red",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "provider-list",
            title: "Parceiros",
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "visible": {
                    return {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent:
                        props.item.status === false ? "flex-start" : "flex-end",
                      width: 48,
                      height: "96%",
                      borderRadius: "1.5vh",
                      padding: ".1vh",
                      backgroundColor:
                        props.item.status === false
                          ? "rgba(234, 236, 240, 1)"
                          : "blue",
                    };
                  }
                  case "visible-line": {
                    return {
                      display: props.item.status === false ? "flex" : "none",
                    };
                  }
                  case "register_type": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "100%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item.register_type === 2
                          ? "rgba(127, 127, 127, 1)"
                          : "rgba(5, 150, 105, 1)",
                      backgroundColor:
                        props.item.register_type === 2
                          ? "rgba(227, 227, 227, 1)"
                          : "rgba(167, 243, 208, 1)",
                    };
                  }
                  default:
                    return {
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Nome",
                style: {
                  flex: 2,
                },
              },
              code: {
                title: "CNPJ",
              },
              address: {
                title: "Local",
                style: {
                  flex: 2.5,
                },
                onClick: () => {},
              },
              order: {
                title: "Atendimentos",
              },
              responsible: {
                title: "Responsável",
                style: {},
              },
              register_type: {
                title: "Status",
                condition: (param) => {
                  if (parseInt(param.register_type) === 2) {
                    return "Cadastrado";
                  } else {
                    return "Fidelizado";
                  }
                  // return param.state.name;
                },
              },
              action: {
                title: "Visibilidade",
                item: [
                  {
                    type: "div",
                    id: "visible",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                    },
                    onClick: async (item) => {
                      const show = await api.provider.show({
                        _id: item._id,
                        status: !item.status,
                      });

                      item.status = show?.data?.data?.status;
                      return item;
                    },
                    children: [
                      {
                        type: "div",
                        style: {
                          flex: 1,
                          display: "flex",
                          height: "100%",
                          width: "auto",
                          aspectRation: "1/1",
                          maxWidth: "42%",
                          maxHeight: "90%",
                          borderRadius: "50%",
                          backgroundColor: "white",
                        },
                      },
                    ],
                  },
                ],
              },
              edit: {
                title: "Ações",
                item: [
                  {
                    type: "image",
                    src: EditProvider,
                    size: 22,
                    style: {
                      display: "flex",
                      border: "none",
                      backgroundColor: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    },
                    onClick: async (info) => {
                      const { data } = await api.utils.getUser({
                        query: {
                          code: info?.code,
                          type: "provider",
                        },
                      });
                      GoPage("provider-edit", { info: data.data.info });
                      console.log("verUser", data.data.info);
                      // console.log("1", data);
                      // setDeleteEmployee(info);
                    },
                  },
                ],
              },
            },
            item: api.provider.list,
          }),
        ],
      },
    ],
  };
};
