import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import api from "service";

export default () => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [accessLevels, setAccessLevels] = useState([]);
  const [levelName, setLevelName] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    const fetchAccessLevels = async () => {
      try {
        const response = await api.management.list_access();
        setAccessLevels(response.data?.data || []);
      } catch (error) {
        // console.error("Erro ao buscar níveis de acesso:", error);
      }
    };

    fetchAccessLevels();
  }, []);

  // Atualiza as permissões no estado
  const handleSwitchChange = (groupName, permissionName, newStatus) => {
    setAccessLevels((prevLevels) =>
      prevLevels.map((group) =>
        group.name === groupName
          ? {
              ...group,
              permission: group.permission.map((perm) =>
                perm.name === permissionName
                  ? { ...perm, status: newStatus }
                  : perm
              ),
            }
          : group
      )
    );
  };

  const handleSubmit = async () => {
    if (!levelName.trim()) {
      setErrors("Por favor, insira um nome para o nível de acesso.");
      return;
    }

    setErrors("");

    const formattedData = {
      name: levelName,
      permission: (accessLevels || []).map((group) => ({
        id: group._id,
        name: group.name,
        access: (group.permission || []).map((perm) => ({
          name: perm.name,
          value: perm.status,
        })),
      })),
    };

    try {
      const response = await api.management.add_access(formattedData);
      if (response.data?.status === true) {
        PopupShow({
          title: "Nível de acesso criado com sucesso!",
          description: "",
          cancelText: "",
          confirmText: "Ir para lista",
          onCancel: async () => {},
          onConfirm: async () => {
            GoPage("access-level");
          },
        });
      } else {
        PopupShow({
          title: "Criação não permitida!",
          description: "",
          cancelText: "",
          style: {
            close: {
              display: "none",
            },
          },
          confirmText: "Voltar para lista",
          onCancel: async () => {},
          onConfirm: async () => {
            GoPage("access-level");
          },
        });
      }
    } catch (error) {
      PopupShow({
        title: "Erro ao criar nível de acesso",
        description:
          error.response?.data?.message || "Ocorreu um erro inesperado.",
        cancelText: "",
        confirmText: "Fechar",
        onCancel: async () => {},
        onConfirm: async () => {},
      });
    }
  };

  return {
    component: "container",
    style: {
      display: PageInfo.show === "accesslevel-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Configuração de níveis de acesso",
        form: {
          onSave: handleSubmit,
          // api.management.add_access,
          // func: (data, info, clear, goBack) => {
          //   console.log("data", data);
          // },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "60%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              padding: "1%",
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "60%",
              },
            },
          },
          box: {
            1: {
              style: {
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 8,
              },
              input: [
                {
                  type: "div",
                  style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    paddingBottom: "2rem",
                    borderBottom: "1px solid #E6E6E6",
                  },
                  children: [
                    {
                      component: "text",
                      text: "Nome do nível de acesso",
                    },
                    {
                      id: "access_name",
                      value: levelName,
                      set: (value) => {
                        setLevelName(value);
                        if (errors) {
                          setErrors("");
                        }
                      },
                      errorMessage: errors,
                      type: "input",
                      style: {
                        border: errors ? "1px solid red" : "1px solid #D0D5DD",
                        borderRadius: 8,
                        padding: 4,
                        fontSize: "1.5vh",
                        width: "12vw",
                        height: "44px",
                      },
                      required: true,
                    },
                  ],
                },
                ...accessLevels?.map((group) => ({
                  type: "div",
                  key: group.name,
                  style: {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderBottom: "1px solid #E6E6E6",
                  },
                  children: [
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "no-wrap",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        flexFlow: "none",
                      },
                      children: [
                        {
                          component: "text",
                          text: group.name,
                          style: {
                            color: "black",
                            width: "100%",
                          },
                        },
                        {
                          type: "div",
                          style: {
                            display: "flex",
                            flex: "none",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            width: "60%",
                            gap: "0",
                          },
                          children: [
                            ...group.permission.map((perm) => ({
                              type: "switch",
                              label: perm.name,
                              style: {
                                display: "flex",
                                flexDirection: "row-reverse",
                                fontFamily: "Poppins",
                                fontSize: 12,
                              },
                              onChange: (e) =>
                                handleSwitchChange(
                                  group.name,
                                  perm.name,
                                  e.target.checked
                                ),
                              checked: perm.status,
                            })),
                          ],
                        },
                      ],
                    },
                  ],
                })),
              ],
            },
          },
        },
      }),
    ],
  };
};
