import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import Button from "components/web/button";
import getElement from "components/web/view/getElement";
import CurrencyIcon from "assets/images/menu/icon-currency.svg";

import Theme from "theme";

export default (popUp, setPopUp, item) => {
  const { setUpdate } = ShowPage();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [formData, setFormData] = useState({});
  const [profileOptions, setProfileOptions] = useState([]);

  useEffect(() => {
    setFormData({
      name,
      nickname: name,
      email,
      profile,
      password,
      confirmPassword,
    });
  }, [name, email, profile, password, confirmPassword]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "profile") {
      setProfile(value); // Define o profile como o ID selecionado
      setFormData((prevData) => ({
        ...prevData,
        profile: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validação do formulário
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "O nome é obrigatório.";
    if (!formData.email) newErrors.email = "O e-mail é obrigatório.";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "E-mail inválido.";
    if (!formData.profile) newErrors.profile = "Nível de acesso é obrigatório.";
    if (formData.password?.length == 0)
      newErrors.password = "A senha é obrigatória.";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "As senhas não coincidem.";

    return newErrors;
  };

  const resetForm = () => {
    setFormData({
      name: "",
      nickName: "",
      email: "",
      profile: "",
      password: "",
      confirmPassword: "",
    });
    setName("");
    setEmail("");
    setProfile("");
    setPassword("");
    setConfirmPassword("");
    setErrors({});
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors); // Atualiza o estado de erros para exibir as mensagens
      return; // Interrompe o envio se houver erros
    }

    try {
      // console.log("formData", {
      //   ...formData,
      // });
      await api.management.add_user(formData);
      setUpdate("user-management");
      resetForm(); // Limpar o formulário após salvar
      setPopUp(false);
    } catch (error) {
      // console.error("Erro ao criar usuário:", error);
    }
  };

  const handleCancel = () => {
    setPopUp(false);
    resetForm();
  };

  useEffect(async () => {
    const query = { status: "active" };
    const response = await api.management.list_profile({ query });

    const options = response.data?.data.list.map((option) => ({
      id: option._id, // Armazena o _id
      name: option.name,
    }));

    setProfileOptions(options);
  }, [popUp]);

  const errorMessages = Array.isArray(errors)
    ? errors.map((error, index) => (
        <div key={index} style={{ color: "red" }}>
          {error}
        </div>
      ))
    : Object.values(errors).map((error, index) => (
        <div key={index} style={{ color: "red" }}>
          {error}
        </div>
      ));

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      maxHeight: 32,
      fontSize: "2vh",
    },
  };

  return {
    component: "container",
    style: {
      display: popUp === true ? "flex" : "none",
      width: "100%",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      borderRadius: 0,
      zIndex: 99,
    },
    children: [
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "28%",
                height: "auto",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
                backgroundColor: "white",
                borderRadius: 8,
                padding: 12,
              },
              children: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  },
                  children: [
                    {
                      component: "text",
                      text: "Criação de novo usuário",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "1.44vw",
                        textAlign: "flex-start",
                        fontWeight: "600",
                        width: "86%",
                      },
                    },
                    {
                      component: "container",
                      style: {
                        width: "23vw",
                        height: "50vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                      },
                      children: [
                        // Campo de nome
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "name",
                              value: formData.name,
                              set: (value) => {
                                setName(value);
                              },
                              label: "Nome",
                              type: "input",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        // Campo de email
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "email",
                              value: formData.email,
                              set: (value) => {
                                setEmail(value);
                              },
                              label: "E-mail",
                              type: "input",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        // Nível de acesso
                        {
                          render: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                height: "70px",
                                width: "100%",
                                gap: "6px",
                              }}
                            >
                              <p style={{ ...Theme.text.input.title }}>
                                Nível de acesso
                              </p>
                              <select
                                name="profile"
                                value={formData.profile}
                                onChange={handleChange}
                                placeholder="Digite aqui"
                                style={{
                                  border: "1px solid #bfbfbf",
                                  borderRadius: 4,
                                  padding: 4,
                                  fontSize: "1.5vh",
                                  width: "100%",
                                  height: "44px",
                                }}
                              >
                                <option value="">Selecione</option>
                                {profileOptions?.map((option) => (
                                  <option key={option._id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ),
                        },
                        // Senha
                        {
                          component: "container",
                          style: {
                            // display: "flex",
                            // flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "40px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "password",
                              value: formData.password,
                              set: (value) => {
                                setPassword(value);
                              },
                              label: "Senha",
                              type: "password",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        // Confirmar senha
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "confirm_password",
                              value: formData.confirmPassword,
                              set: (value) => {
                                setConfirmPassword(value);
                              },
                              label: "Confirmar senha",
                              type: "password",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        {
                          component: "text",
                          text: errorMessages,
                          style: {
                            color: "red",
                          },
                        },
                      ],
                    },
                  ],
                },
                // Botão de salvar
                Button({
                  label: "Salvar",
                  style: {
                    button: {
                      backgroundColor: "#0864F1",
                      width: "50%",
                      alignItems: "center",
                      height: "44px",
                      color: "white",
                      borderRadius: 8,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    handleSubmit();
                  },
                }),
                Button({
                  label: "Cancelar",
                  style: {
                    button: {
                      backgroundColor: "white",
                      width: "100%",
                      alignItems: "center",
                      height: "44px",
                      color: "#0864F1",
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    handleCancel();
                  },
                }),
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
