import Theme from "theme";
import Icon from "components/web/icon";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

export default (props) => {
  return {
    component: "container",
    style: {
      ...Theme.box.home.card.main,
      marginRight: 0,
      marginTop: 0,
      width: "32%",
      ...props.style?.main,
    },
    children: [
      // topbar
      {
        component: "container",
        style: {
          ...Theme.box.home.card.top.main,
          ...props.style?.top?.main,
        },
        children: [
          // Icon Title + Count
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            },
            children: [
              // Icon + text
              {
                component: "container",
                style: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 8,
                },
                children: [
                  Icon({
                    size: "4vh",
                    style: {
                      iconBox: {
                        width: "18%",
                        ...props.style?.iconBox,
                      },
                    },
                    src: props.icon || "",
                  }),
                  //title + count
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      minHeight: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 4,
                      marginLeft: "2%",
                    },
                    children: [
                      //title
                      {
                        render: (
                          <Fade top left>
                            {Render({
                              component: "text",
                              text: props.title || "Title",
                              style: {
                                fontFamily: "Poppins",
                                textAlign: "center",
                                height: "100%",
                                color: "gray",
                                fontSize: "1.22vw",
                                ...props.style?.top?.title,
                              },
                            })}
                          </Fade>
                        ),
                      },
                      //count
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          marginTop: 4,
                          gap: 2,
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        },
                        children: props?.count?.map((item) => {
                          return {
                            component: "text",
                            text: item.value || "-",
                            style: {
                              marginTop: 4,
                              width: "100%",
                              fontSize: "0.62vw",
                              fontFamily: "Poppins",
                              textAlign: "flex-start",
                              ...props.style?.countText,
                            },
                          };
                        }),
                      },
                    ],
                  },
                ],
              },
            ],
          },

          // Action
          {
            component: "container",
            style: {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              marginLeft: "1%",
              flex: 0.4,
            },
            children: [
              // criar lista de icones laterais de ações...
              // Ver todos
              {
                component: "container",
                style: {
                  ...Theme.box.home.card.action.seeAll.main,
                  width: "8vh",
                  marginLeft: "2vw",
                  ...props.style?.action?.seeAll.main,
                },
                onClick: props.action?.seeAll ? props.action.seeAll : () => {},
                children: [
                  {
                    component: "text",
                    text: "Ver todos",
                    style: {
                      ...Theme.box.home.card.action.seeAll.title,
                      ...props.style?.action?.seeAll?.title,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      // Actions
      {
        component: "container",
        style: {
          ...Theme.box.home.card.action.main,
          ...props.style?.action?.main,
        },
        children: props.action.btn || [],
      },
    ],
  };
};
