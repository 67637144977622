// Icon component
import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

import SVG from "./svg";

import ClickSound from "assets/sounds/click.mp3";

import { Howl } from "howler";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const soundPlay = async (src, time, interval) => {
  const sound = new Howl({
    src,
    html5: true,
    volume: 0.5,
    loop: false,
  });
  for (let i = 0; i < time; i++) {
    await delay((interval || 0) * i);
    sound.play();
  }
};

const imageSvg = (src) => {
  const image =
    '<svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" width="47.4" height="40.65" viewBox="21 18.5 158 135.5"><path d="M25,50 l150,0 0,100 -150,0 z" stroke-width="4" stroke="black" fill="rgb(128,224,255)" fill-opacity="1" ></path><path d="M25,50 L175,150 M25,150 L175,50" stroke-width="4" stroke="black" fill="black" ></path><g transform="translate(0,0)" stroke-width="4" stroke="black" fill="none" ><circle cx="100" cy="30" r="7.5" fill="black" ></circle><circle cx="70" cy="30" r="7.5" fill="black" ></circle><circle cx="130" cy="30" r="7.5" fill="black" ></circle></g></svg>';

  // const buff = new Buffer(src);
  // const base64data = buff.toString("base64");
  // alert("aqui");

  return (
    <div className="App">
      <svg
        xmlns={src}
        width="48"
        height="48"
        fill="currentColor"
        class="bi bi-google"
        viewBox="0 0 16 16"
      >
        <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
      </svg>
    </div>
  );
  // return <div style="background:url('" + src + "')" />;

  // return <img src="data:image/svg+xml;base64,${base64data }" alt="" />;
};
// const icone = SVG();

export default (param) => {
  return {
    component: "container",
    style: {
      width: "100%",
      height: "100%",
      aspectRatio: "1/1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      gap: 4,
      ...param?.style?.iconBox,
    },
    onClick: async () => {
      if (param?.onPress)
        try {
          soundPlay(ClickSound, 1);
          await param.onPress();
        } catch (err) {
          console.log("icon err", err);
        }
    },
    children: [
      //icon
      {
        render: param.src ? (
          <img
            style={{
              height: param.size || "22%",
              alignItems: "center",
              justifyContent: "center",
              aspectRatio: "1/1",
              transition: "transform 0.1s ease",
              ...param?.style?.image,
            }}
            src={param.src || ""}
            title={param.titleImg || ""}
          />
        ) : null,
      },
      //title
      {
        render: (
          <Fade top>
            {Render({
              component: "text",
              text: param?.title || "",
              style: {
                display: param.title?.length > 0 ? "flex" : "none",
                fontSize: 12,
                flex: 1,
                alignItems: "flex-start",
                textAlign: "flex-start",
                justifyContent: "flex-start",
                fontFamily: Theme.fontFamilies.secondary,
                ...param?.style?.title,
              },
            })}
          </Fade>
        ),
      },
      //srcArrow
      {
        render: param.srcArrow ? (
          <img
            style={{
              display: param.srcArrow ? "flex" : "none",
              height: 14,
              alignItems: "center",
              justifyContent: "center",
              aspectRatio: "1/1",
              transition: "transform 0.1s ease",
              ...param?.style?.arrow,
            }}
            src={param.srcArrow}
          />
        ) : null,
      },
    ],
  };
};
