import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import Button from "components/web/button";
import getElement from "components/web/view/getElement";

import Theme from "theme";

export default (popUpEdit, setPopUpEdit, item) => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [formData, setFormData] = useState({
    type: "manager",
  });
  const [profileOptions, setProfileOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  // Preencher o formulário com os dados do item quando ele estiver disponível
  useEffect(() => {
    if (item) {
      setFormData({
        _id: item._id,
        name: item.name || "",
        email: item.email || "",
        profile: item.profile?._id || "",
      });
    }
  }, [popUpEdit]);

  //Monitora mudanças no formData para ver o valor atualizado
  const handleChangeSelect = (e) => {
    const { name, value } = e.target;

    if (name === "profile") {
      const selectedProfile = profileOptions.find(
        (option) => option.id === value
      );
      if (selectedProfile) {
        setProfile(selectedProfile.id); // Armazena o _id do perfil selecionado
        setFormData((prevData) => ({
          ...prevData,
          profile: selectedProfile.id, // Atualiza o formData com o ID correto
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          profile: "",
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validação do formulário
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = "O nome é obrigatório.";
    if (!formData.email) newErrors.email = "O e-mail é obrigatório.";
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = "E-mail inválido.";
    if (!formData.profile) newErrors.profile = "Nível de acesso é obrigatório.";

    // Validação para senha e confirmação de senha
    if (formData.password && formData.confirmPassword) {
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "As senhas não coincidem.";
      }
    } else if (!formData.password && formData.confirmPassword) {
      newErrors.confirmPassword = "Digite a senha antes de salvar.";
    } else if (formData.password && !formData.confirmPassword) {
      newErrors.confirmPassword =
        "Digite a confirmação de senha antes de salvar.";
    }

    return newErrors;
  };

  const resetForm = () => {
    setFormData({
      _id: "",
      name: "",
      email: "",
      profile: "",
      password: "",
      confirmPassword: "",
    });
    setErrors({});
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    setErrors(errors);

    // Verifique se não há erros antes de prosseguir
    if (Object.keys(errors).length === 0) {
      try {
        // Função para identificar os campos alterados
        const getChangedFields = (originalData, currentData) => {
          const updatedFields = {};
          for (const key in currentData) {
            if (currentData[key] !== originalData[key]) {
              updatedFields[key] = currentData[key];
            }
          }
          return updatedFields;
        };

        // Obtém apenas os campos alterados
        const updatedFields = getChangedFields(item, formData);

        if (Object.keys(updatedFields).length > 0) {
          // Inclui o `_id` para identificação do registro a ser atualizado
          updatedFields._id = item._id;

          // Envia somente os campos alterados ao back-end
          await api.utils.update(updatedFields);
          // console.log("updatedFields", updatedFields);
          setUpdate("user-management");
          resetForm(); // Limpar o formulário após salvar
          setPopUpEdit(false);
        } else {
          // console.log("Nenhuma alteração detectada.");
        }
      } catch (error) {
        console.error("Erro ao atualizar usuário:", error);
      }
    }
  };

  const handleCancel = () => {
    setPopUpEdit(false);
    resetForm();
  };

  useEffect(async () => {
    const query = { status: "active" };
    const response = await api.management.list_profile({ query });

    const options = response.data?.data.list?.map((option) => ({
      id: option._id,
      name: option.name,
    }));

    setProfileOptions(options);
  }, [popUpEdit]);

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      maxHeight: 32,
      fontSize: "2vh",
    },
  };

  const errorMessages = Array.isArray(errors)
    ? errors.map((error, index) => (
        <div key={index} style={{ color: "red" }}>
          {error}
        </div>
      ))
    : Object.values(errors).map((error, index) => (
        <div key={index} style={{ color: "red" }}>
          {error}
        </div>
      ));

  return {
    component: "container",
    style: {
      display: popUpEdit === true ? "flex" : "none",
      width: "100%",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      borderRadius: 0,
      zIndex: 99,
    },
    children: [
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                flexDirection: "column",
                width: "28%",
                height: "auto",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 4,
                backgroundColor: "white",
                borderRadius: 8,
                padding: 12,
              },
              children: [
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  },
                  children: [
                    {
                      component: "text",
                      text: "Edição de usuário",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "1.44vw",
                        textAlign: "flex-start",
                        fontWeight: "600",
                        width: "86%",
                      },
                    },
                    {
                      component: "container",
                      style: {
                        width: "23vw",
                        height: "46vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: "15px",
                      },
                      children: [
                        // Campo de nome
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "name",
                              value: formData.name,
                              set: (value) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  name: value,
                                }));
                              },
                              label: "Nome",
                              type: "input",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "email",
                              value: formData.email,
                              set: (value) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  email: value,
                                }));
                              },
                              label: "E-mail",
                              type: "input",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        {
                          render: (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                height: "70px",
                                width: "100%",
                                gap: "6px",
                              }}
                            >
                              <p style={{ ...Theme.text.input.title }}>
                                Nível de acesso
                              </p>
                              <select
                                name="profile"
                                value={formData.profile}
                                onChange={handleChangeSelect}
                                placeholder="Digite aqui"
                                style={{
                                  border: "1px solid #bfbfbf",
                                  borderRadius: 4,
                                  padding: 4,
                                  fontSize: "1.5vh",
                                  width: "100%",
                                  height: "44px",
                                }}
                              >
                                <option value="">Selecione</option>
                                {profileOptions?.map((option) => (
                                  <option key={option._id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ),
                        },
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "password",
                              value: formData.password,
                              set: (value) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  password: value,
                                }));
                              },
                              label: "Senha",
                              type: "password",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        {
                          component: "container",
                          style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            height: "60px",
                            width: "100%",
                            gap: "2px",
                          },
                          children: [
                            getElement({
                              id: "confirm_password",
                              value: formData.confirmPassword,
                              set: (value) => {
                                setFormData((prevData) => ({
                                  ...prevData,
                                  confirmPassword: value,
                                }));
                              },
                              label: "Confirmar senha",
                              type: "password",
                              style: inputStyle,
                              required: true,
                            }),
                          ],
                        },
                        {
                          component: "text",
                          text: errorMessages,
                          style: {
                            color: "red",
                          },
                        },
                      ],
                    },
                  ],
                },
                Button({
                  label: "Salvar",
                  style: {
                    button: {
                      backgroundColor: "#0864F1",
                      width: "50%",
                      alignItems: "center",
                      height: "44px",
                      color: "white",
                      borderRadius: 8,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    handleSubmit();
                  },
                }),
                Button({
                  label: "Cancelar",
                  style: {
                    button: {
                      backgroundColor: "white",
                      width: "100%",
                      alignItems: "center",
                      height: "44px",
                      color: "#0864F1",
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    handleCancel();
                  },
                }),
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
