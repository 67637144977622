// Init Page para valida algumas infoamções e selcionar para qual pagina direcionar..

// verificar historico, ou url com parametros..

import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import ListView from "components/web/view/list";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

export default (props) => {
  const { PageInfo, GoPage } = ShowPage();

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  // Business - Lista
  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "business-list" ? "flex" : "none",
      // backgroundColor: "red",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "business-list",
            title: "Empresas",
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "address": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "normal",
                    };
                  }
                  case "status": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "100%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item[props.col] === true
                          ? "rgba(5, 150, 105, 1)"
                          : "rgba(75, 85, 99, 1)",
                      backgroundColor:
                        props.item[props.col] === true
                          ? "rgba(167, 243, 208, 1)"
                          : "rgba(229, 231, 235, 1)",
                    };
                  }
                  default:
                    return {
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Nome",
                style: {
                  flex: 2,
                },
              },
              code: {
                //cnpj = code
                title: "CNPJ",
              },
              address: {
                title: "Local",
                style: {
                  flex: 2,
                },
                onClick: () => {
                  // console.log("edit");
                  // GoPage("AddPeople", {
                  //   page: {
                  //     title:
                  //       "Editar Colaborador " +
                  //       (info.id || info._id || ""),
                  //   },
                  //   info,
                  // });
                },
              },
              employee: {
                title: "N.° funcionários",
              },
              responsible: {
                title: "Responsável",
                style: {},
              },
              register_date: {
                title: "Registro",
                style: {
                  flex: 1.2,
                },
              },
              status: {
                title: "Acesso",
                condition: (param) => {
                  if (param.status === true) {
                    return "Ativo";
                  } else {
                    return "Inativo";
                  }
                },
              },
            },
            item: api.business.list,
          }),
        ],
      },
    ],
  };
};
