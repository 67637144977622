import { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { StyleClass } from 'primereact/styleclass';
import ContentTopBar from "components/web/ContentTopBar";

export default (props) => {

  addLocale('pt', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['jan', 'feb', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

  return {
    component: "container",
    style: {
      // width: "45%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      marginLeft: "0.5rem",
      justifyContent: "flex-start",
    },
    children: [
      {
        render:(
          <Calendar value={props.dates} onChange={props.onChange} selectionMode="range" readOnlyInput hideOnRangeSelection
          dateFormat="dd/mm/yy" locale="pt"  />
        )
      }
    ],
  };
};
