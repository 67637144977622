import Theme from "theme";
import React, { useState, useEffect } from "react";
import { Context } from "context/data";
import Logo from "assets/images/logo.png";
import Home from "assets/images/home.png";
import Logout from "assets/images/logout.png";

import LogoClose from "assets/images/menu/icon-close-petin.svg";
import iconProfile from "assets/images/menu/avatar-profile.svg";

import IconGoRight from "assets/images/menu/goRight.svg";
import Arrow from "assets/images/menu/arrowDown.svg";
import ArrowUp from "assets/images/menu/creatUp.svg";

import ClickSound from "assets/sounds/click.mp3";

import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import { ShowPopup } from "context/popup";

import { Howl } from "howler";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const soundPlay = async (src, time, interval) => {
  const sound = new Howl({
    src,
    html5: true,
    volume: 0.5,
    loop: false,
  });
  for (let i = 0; i < time; i++) {
    await delay((interval || 0) * i);
    sound.play();
  }
};

export default (props) => {
  const { GoInitPage, GoPage, GoBack, PageInfo } = ShowPage();
  const { Data, Set } = Context();

  const { PopupShow, PopupClose } = ShowPopup();

  const [btnSelected, setBtnSelected] = useState(["Home"]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [nameUser, setNameUser] = useState("");
  const [emailUser, setEmailUser] = useState("");

  useEffect(() => {
    setNameUser(Data.user?.name || Data.user?.nickname);
    setEmailUser(Data.user?.email);
  }, [Data.user]);

  const handleIconClick = () => {
    soundPlay(ClickSound, 1);
    setMenuOpen((prevState) => !prevState);
  };

  // Função para alternar o estado da navbar com duplo clique
  let clickTimer = null;

  const handleDoubleClick = () => {
    if (clickTimer) {
      clearTimeout(clickTimer);
      clickTimer = null;
      setMenuOpen((prevState) => !prevState); // Alterna a navbar
    } else {
      clickTimer = setTimeout(() => {
        clickTimer = null; // Reseta o timer se não houver segundo clique rápido
      }, 300); // Intervalo máximo para detectar o duplo clique (300ms)
    }
  };

  const handleMenuClick = (page) => {
    GoPage(page);
    setBtnSelected([page]);
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(page)
        ? prevExpandedItems.filter((item) => item !== page)
        : [...prevExpandedItems, page]
    );
  };

  // useEffect(() => {
  //   GoPage("order-buy");
  // }, [PageInfo]);

  return {
    component: "container",
    style: {
      minWidth: menuOpen === true ? 232 : 64,
      maxWidth: menuOpen === true ? 232 : 64,
      maxHeight: "98%",
      height: "98%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      boxShadow: "1px 1px 8px #bfbfbf",
      transition: "min-width 0.5s ease, transform 0.5s ease",
      paddingTop: 12,
      padding: 6,
      paddingLeft: menuOpen === true ? 12 : 6,
      userSelect: "none",
      gap: 12,
      cursor: "pointer",
      ...props?.navBarBox?.style,
    },
    onClick: () => {
      handleDoubleClick();
    },
    children: [
      //logo + iconGo
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: 38,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        },
        children: [
          //iconPetin
          {
            render: (
              <Fade>
                {Render({
                  component: "image",
                  style: {
                    width: "auto",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    transition: "min-width 0.5s ease, transform 0.5s ease",
                    cursor: "pointer",
                  },
                  src: menuOpen === true ? Logo : LogoClose,
                  onClick: () => {
                    handleIconClick();
                  },
                })}
              </Fade>
            ),
          },
          //Icon (goRight >> / <<goLeft)
          {
            component: "container",
            style: {
              display: menuOpen === true ? "flex" : "none",
              height: 22,
              aspectRatio: "1/1",
              transition: "transform 0.3s ease-in-out",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
            },
            children: [
              Icon({
                size: "100%",
                src: IconGoRight,
                style: {
                  image: {
                    right: 0,
                    transform:
                      menuOpen === true ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 0.3s ease-in-out",
                  },
                },
                onPress: () => {
                  handleIconClick();
                },
              }),
            ],
          },
        ],
      },
      //Profile + info
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: 42,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          cursor: "pointer",
          marginTop: 24,
        },
        children: [
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: menuOpen === true ? "flex-start" : "center",
              borderRadius: 4,
              gap: 4,
              transition: "background-color 0.5s ease-in-out",
              background: btnSelected.includes(props?.profile?.page)
                ? "rgba(228, 238, 255, 1)"
                : "transparent",
            },
            children: [
              //iconProfile
              {
                render: (
                  <Fade>
                    {Render({
                      component: "image",
                      style: {
                        height: "90%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        borderRadius: "50%",
                        transition: "min-width 0.5s ease, transform 0.5s ease",
                      },
                      src: props?.profile?.iconProfile || iconProfile,
                    })}
                  </Fade>
                ),
              },
              //text
              {
                render: (
                  <Fade left>
                    {Render({
                      component: "container",
                      style: {
                        display: menuOpen === true ? "flex" : "none",
                        width: "82%",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      },
                      children: [
                        {
                          component: "text",
                          text: nameUser || "-_-",
                          style: {
                            width: "100%",
                            height: 18,
                            fontFamily: "Poppins",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                        {
                          component: "text",
                          text: emailUser || "-_-",
                          style: {
                            fontSize: 12,
                            color: "gray",
                            width: "100%",
                            fontFamily: "Poppins",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          },
                        },
                      ],
                    })}
                  </Fade>
                ),
              },
            ],
          },
        ],
        onClick: async () => {
          soundPlay(ClickSound, 1);
          handleMenuClick(props?.profile?.page);
        },
      },
      //menu + logout
      {
        component: "container",
        style: {
          maxHeight: "90%",
          height: "90%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: 20,
          overflow: "auto",
        },
        children: [
          {
            component: "container",
            style: {
              flex: 1,
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 8,
              overflowY: "auto",
              overflowX: "hidden",
              ...props?.menu?.style,
            },
            children: props?.item?.map((menu) => {
              return {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 2,
                  flexDirection: "column",
                  cursor: "pointer",
                },
                children: [
                  //Icon + title
                  Icon({
                    size: 20,
                    title: menu?.title,
                    src: menu.image?.src,
                    titleImg: menu?.title,
                    style: {
                      iconBox: {
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 4,
                        padding: 6,
                        height: "4vh",
                        width: "100%",
                        transition: "background-color 0.5s ease-in-out",
                        background: btnSelected.includes(menu.page)
                          ? "rgba(228, 238, 255, 1)"
                          : "transparent",
                        ...menu?.style?.iconBox,
                      },
                      title: {
                        fontSize: 12,
                        display: menuOpen === true ? "flex" : "none",
                        color: btnSelected.includes(menu.page)
                          ? "rgba(17, 24, 39, 1)"
                          : "gray",
                        fontWeight: "400",
                        textAlign: "flex-start",
                        maxWidth: "100%",
                      },
                      image: {},
                      arrow: {
                        display: menuOpen === true ? "flex" : "none",
                        transform: expandedItems.includes(menu.page)
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        fill: expandedItems.includes(menu.page)
                          ? "blue"
                          : "black",
                        transition: "transform 0.4s ease-in-out",
                      },
                    },
                    srcArrow:
                      menu.children?.length > 0 &&
                      btnSelected.includes(menu.page)
                        ? ArrowUp
                        : menu.image?.srcArrow,
                    onPress: () => handleMenuClick(menu.page),
                  }),
                  //subMenu
                  {
                    component: "container",
                    style: {
                      display:
                        expandedItems.includes(menu.page) &&
                        menuOpen === true &&
                        menu.children?.length > 0
                          ? "flex"
                          : "none",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 4,
                      paddingLeft: "14%",
                      gap: 8,
                      zIndex: 9,
                    },
                    children: menu.children?.map((subMenu) => {
                      return Icon({
                        size: 20,
                        title: subMenu?.title,
                        src: subMenu.image?.src,
                        style: {
                          iconBox: {
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            maxHeight: "4vh",
                            borderRadius: 4,
                            justifyContent:
                              menuOpen === true ? "flex-start" : "center",
                            transition: "background-color 0.5s ease-in-out",
                            background: btnSelected.includes(subMenu.page)
                              ? "rgba(228, 238, 255, 1)"
                              : "transparent",
                          },
                          title: {
                            fontSize: 12,
                            display: menuOpen === true ? "flex" : "none",
                            fontWeight: "400",
                            paddingLeft: 12,
                            color: btnSelected.includes(subMenu.page)
                              ? "rgba(17, 24, 39, 1)"
                              : "gray",
                            textAlign: "flex-start",
                          },
                          image: {},
                        },
                        onPress: () => {
                          setBtnSelected([menu.page, subMenu.page]);
                          if (subMenu.onClick)
                            try {
                              subMenu.onClick(subMenu.page);
                            } catch (e) {}
                          else {
                            GoPage(subMenu.page);
                          }
                        },
                      });
                    }),
                  },
                ],
              };
            }),
          },
          //logout/version
          {
            component: "container",
            style: {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              ...props?.logoutBox?.style,
            },
            children: [
              Icon({
                src: Logout,
                size: 20,
                title: "Sair",
                style: {
                  iconBox: {
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRadius: 4,
                    padding: 6,
                    height: "4vh",
                    justifyContent: menuOpen === true ? "flex-start" : "center",
                    transition: "background-color 0.5s ease-in-out",
                  },
                  title: {
                    fontSize: 12,
                    display: menuOpen === true ? "flex" : "none",
                    fontWeight: "400",
                    textAlign: "flex-start",
                    color: "red",
                  },
                  image: {},
                },
                onPress: () => {
                  PopupShow({
                    title: "Deseja mesmo sair?",
                    confirmText: "sim",
                    cancelText: "não",
                    onConfirm: () => {
                      localStorage.clear();
                      sessionStorage.clear();
                      window.location.reload();
                    },
                  });
                },
              }),
              {
                component: "text",
                text: "V: " + process.env.REACT_APP_VERSION,
                style: {
                  display: menuOpen === true ? "flex" : "none",
                  width: "50%",
                  color: "gray",
                  fontSize: 12,
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontFamily: Theme.fontFamilies.secondary,
                },
              },
            ],
          },
        ],
      },
    ],
  };
};
