import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import Button from "components/web/button";

//images

//pages

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "summary-list" ? "flex" : "none",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "summary-list",
            title: "Financeiro resumo",
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontWeight: "normal",
                      // flex: 1.2,
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 64,
                maxHeight: 64,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              id: {
                title: "ID",
                condition: (id) => {
                  return "# " + id.id;
                },
                style: {
                  flex: "0.5",
                },
              },
              client: {
                key: ["people", "name"],
                title: "Cliente",
              },
              supplier: {
                key: ["people", "business", "nickname"],
                title: "Fornecedor",
                style: {},
              },
              code: {
                key: ["business", "code"],
                title: "CNPJ",
              },
              service_date: {
                title: "Data do serviço",
                style: {
                  flex: "1.5",
                },
              },
              expiration: {
                title: "Vencimento",
              },
              service_category: {
                title: "Categoria de serviço",
                style: {
                  flex: "1.5",
                },
                condition: (param) => {
                  const serviceNames = param.services.map(
                    (item) => item?.service?.category?.name
                  );
                  return serviceNames.length > 1
                    ? `${serviceNames[0]}...`
                    : serviceNames[0];
                },
              },
              payment_type: {
                title: "Forma de Pagamento",
                style: {
                  flex: "1.2",
                },
              },
              gmv: {
                title: "GMV",
              },
              takerate: {
                title: "Takerate",
              },
              cashback: {
                condition: (param) => {
                  return "R$ " + param?.people.credit;
                },
                title: "Cashback",
              },
              card_tax: {
                title: "Taxa do cartão",
              },
            },
            item: api.financial.summary,
          }),
        ],
      },
    ],
  };
};
