import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import CalendarView from "components/web/suggestHour";
import Button from "components/web/button";

//images
import Close from "assets/images/menu/close.png";

import Theme from "theme";

export default (popUp, setPopUp, item, selectedHours, setSelectedHours) => {
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (item?.services?.length > 0) {
      setCategory(item.services[0].service.category._id);
    }
  }, [item]);
  return {
    component: "container",
    style: {
      display: popUp === true ? "flex" : "none",
      width: "100%",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      borderRadius: 0,
      zIndex: 99,
    },
    children: [
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "52%",
                height: "auto",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: 4,
                backgroundColor: "white",
                borderRadius: 8,
                padding: 12,
              },
              children: [
                //labelEdit
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                  children: [
                    {
                      component: "text",
                      text: "Selecione a data e horário desejado",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "1.44vw",
                        textAlign: "center",
                        fontWeight: "600",
                      },
                    },
                    {
                      component: "image",
                      src: Close,
                      style: {
                        width: 24,
                        aspectRatio: "1/1",
                        alignItems: "center",
                        cursor: "pointer",
                      },
                      onClick: async () => {
                        setPopUp(false);
                      },
                    },
                  ],
                },
                // calendarview
                {
                  render: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {Render(
                        CalendarView({
                          id: item?.id,
                          category,
                          provider: item?.provider?._id,
                          targetDate: item?.service_date_number,
                          onChange: async (dates) => {
                            setSelectedHours(dates);
                            // console.log("Datee", dates, selectedHours);
                          },
                        })
                      )}
                    </div>
                  ),
                },

                Button({
                  label: "Sugerir horário",
                  style: {
                    button: {
                      backgroundColor:
                        selectedHours.length !== 0
                          ? Theme.colors.pink.dark
                          : "gray",
                      alignItems: "center",
                      border: "none",
                      color: "#fff",
                      width: 250,
                      height: 50,
                      borderRadius: 12,
                      cursor: "pointer",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  disabled: selectedHours !== 0,
                  onPress: async () => {
                    await api.provider.suggestTime({
                      order: item?._id,
                      suggested_time: selectedHours,
                      provider: item?.provider?._id,
                    });
                    setPopUp(false);
                  },
                }),
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
