import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Calendar } from "primereact/calendar";
import { PrimeReactProvider } from "primereact/api";

//context
import { ShowPage } from "context/showPage";

//images

//theme
import Theme from "theme";

//api
import api from "service";

//components
import indicatorBox from "components/web/indicatorFinanceBox";
import ContentTopBar from "components/web/ContentTopBar";
import titleDateFinance from "components/web/titleDateFinance";

//pages
import Page from "../../init";

export default (props) => {
  const { GoPage, PageInfo } = ShowPage();

  // Calculando o primeiro e o último dia do mês atual
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [dates, setDates] = useState([firstDayOfMonth, lastDayOfMonth]);
  const [financialData, setFinancialData] = useState({
    grossMerchandiseValue: 0,
    revenue: 0,
    supplierOutflows: 0,
    cashback: 0,
    credit: 0,
    averageTicket: 0,
  });

  useEffect(() => {
    const body = {
      start: firstDayOfMonth.getTime(),
      end: lastDayOfMonth.getTime(),
    };
    api.financial
      .indicators(body)
      .then((response) => {
        // console.log("Body com sucesso:", body);
        // console.log("dados", response.data?.data);
      })
      .catch((error) => {
        console.error("Erro ao carregar os dados iniciais:", error);
      });
  }, []);

  // Atualização dos dados ao selecionar datas no calendário
  useEffect(() => {
    if (dates?.length === 2 && dates[0] && dates[1]) {
      const body = {
        start: dates[0].getTime(),
        end: dates[1].getTime(),
      };

      api.financial
        .indicators(body)
        .then((response) => {
          if (response.data && response.data.data) {
            const {
              grossMerchandiseValue,
              revenue,
              supplierOutflows,
              cashback,
              credit,
              averageTicket,
            } = response.data.data;

            setFinancialData({
              grossMerchandiseValue,
              revenue,
              supplierOutflows,
              cashback,
              credit,
              averageTicket,
            });
          } else {
            console.warn("Dados ausentes na resposta da API:", response.data);
          }
        })
        .catch((error) => {
          console.error("Erro ao enviar as datas:", error);
        });
    }
  }, [dates]);

  return {
    render: (
      <div
        style={{
          flex: 1,
          display: PageInfo.show === "finance-indicators" ? "flex" : "none",
        }}
      >
        <Fade left>
          {Render({
            component: "container",
            style: {
              width: "100%",
              height: "100%",
            },
            children: [
              {
                component: "container",
                style: {
                  width: "100%",
                  padding: "1%",
                  marginBottom: 8,
                  borderRadius: 4,
                },
                children: [
                  ContentTopBar({
                    title: "Principais indicadores",
                    search: [],
                    action: [],
                  }),
                ],
              },
              // {
              //   component: "container",
              //   style: {
              //     border: "1px solid red",
              //     display: "flex",
              //         alignItems: "center",
              //         justifyContent: "flex-start",
              //         width: "100%",
              //   },
              //   children:[
              //    titleDateFinance({
              //     dates: dates,
              //     onChange:(e) => setDates(e.value)
              //    }),
              //    ],
              // },
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: "5vh",
                  paddingLeft: "5vh",
                  gap: "2.66vh",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "65%",
                    },
                    children: [
                      titleDateFinance({
                        dates: dates,
                        onChange: (e) => setDates(e.value),
                      }),
                    ],
                  },

                  //GMV + Faturamento + Saida fornecedores
                  {
                    component: "container",
                    style: {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "1.66vh",
                    },
                    children: [
                      //GMV
                      indicatorBox({
                        title: "GMV",
                        value: `R$${financialData?.grossMerchandiseValue}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                      //GMV
                      indicatorBox({
                        title: "Faturamento",
                        value: `R$${financialData.revenue}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                      //GMV
                      indicatorBox({
                        title: "Saída fornecedores",
                        value: `R$${financialData.supplierOutflows}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                    ],
                  },
                  //Geração de cashback + Utilização de crédito + Ticket médio
                  {
                    component: "container",
                    style: {
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "1.66vh",
                    },
                    children: [
                      //Geração de cashback
                      indicatorBox({
                        title: "Geração de cashback",
                        value: `R$${financialData.cashback}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                      //Utilização de crédito
                      indicatorBox({
                        title: "Utilização de crédito",
                        value: `R$${financialData.credit}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                      //Ticket médio
                      indicatorBox({
                        title: "Ticket médio",
                        value: `R$${financialData.averageTicket}`,
                        style: {
                          main: {
                            width: "22vw",
                            top: 0,
                          },
                          top: {
                            main: {},
                            title: {},
                            count: {},
                          },
                        },
                      }),
                    ],
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
