import Theme from "theme";
import React from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import getElement from "components/web/view/getElement";

export default (opt) => {
  return {
    render: (
      <Fade>
        <div
          style={{
            ...Theme.box.default,
            ...opt.style?.main,
          }}
        >
          {opt.children?.map((item) => {
            item.props = opt.props;
            return Render(item);
          })}
        </div>
      </Fade>
    ),
  };
};
