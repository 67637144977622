import Theme from "theme";
import Box from "components/web/box";
import Button from "components/web/button";
import BoxList from "components/web/boxInputList";
import Input from "components/web/input";
import Select from "components/web/select";
import FileLoad from "components/web/fileLoad";
import Switch from "components/web/switch";
import Icon from "components/web/icon";
import InputCurrency from "components/web/inputCurrency";
import { ShowPage } from "context/showPage";

const getElement = (item) => {
  const { PageInfo, SetRequestData } = ShowPage();

  if (!item.type || item.type === "text")
    return {
      component: "text",
      text: item.label || item.text,
      style: {
        color: Theme.colors.black,
        fontWeight: "400",
        ...item.style,
      },
      onClick: () => {
        if (item?.onClick)
          try {
            item?.onClick();
          } catch (error) {}
      },
    };
  else if (item.type === "button")
    return {
      component: "container",
      style: {
        display: "flex",
        ...item.style?.button,
      },
      onClick: item?.onClick,
      children: [
        {
          component: "text",
          text: item?.label,
          style: {
            fontSize: "1vw",
            color: Theme.colors.black,
            fontFamily: Theme.fontFamilies.primary,
            ...item.style?.label,
            cursor: item?.onClick ? "pointer" : "normal",
          },
        },
      ],
    };
  // else if (item.type === "button") {
  //   return Button({
  //     label: item?.label,
  //     onPress: () => {
  //       if (item?.onClick)
  //         try {
  //           item?.onClick();
  //         } catch (error) {}
  //     },
  //     style: {
  //       button: {
  //         backgroundColor: "transparent",
  //         ...item?.style?.button,
  //       },
  //       label: item?.style?.label,
  //     },
  //   });
  // }
  else if (item.type === "div" || item.type === "container") {
    let val = item.props?.value || "";
    if (val === undefined) val = {};
    return {
      component: "container",
      style: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: 8,
        cursor: item.onClick ? "pointer" : "",
        ...item.style,
      },
      onClick: () => {
        if (item.onClick)
          try {
            item.onClick();
          } catch (error) {}
      },
      children: item.children?.map((child) => {
        // console.log("element", child);
        child.info = item.info;
        return getElement(child);
      }),
    };
  } else if (item.type === "image") {
    return Icon({
      style: {
        iconBox: item?.style?.iconBox,
      },
      src: item?.src,
      size: item?.size,
      onPress: () => {
        if (item?.onClick)
          try {
            item?.onClick();
          } catch (error) {}
      },
    });
  }
  // box
  else if (item.type === "box") {
    let val = item.props?.value || "";
    if (val === undefined) val = {};
    // if (!val[item.id]) val[item.id] = {};
    // val = val[item.id];
    // console.log(item);
    return Box({
      style: {
        ...item.style,
      },
      children:
        item.children?.map((child) => {
          let childVal = val[child.id];

          if (childVal === undefined) childVal = child.type === "box" ? {} : "";

          // if (item.keyid) child.keyid = item.keyid + "." + child.id;

          // console.log("aaa", item);
          child.props = {
            set: item.props?.set,
            value: childVal,
          };
          return getElement(child);
        }) || [],
    });
  }
  // select
  else if (item.type === "select") {
    const errorMessage = item?.errorMessage || "";

    return {
      component: "container",
      style: {
        display: "flex",
        flexDirection: "column",
      },
      children: [
        Select({
          title: item?.label || "",
          style: {
            box: { flex: 1, ...item?.style },
            title: item?.style?.title,
            text: { fontSize: "1vw", ...item.style?.text },
          },
          value: item?.value,
          onChange: (e) => {
            if (item?.func) {
              try {
                item.func(e);
              } catch (error) {
                console.error("Erro ao executar a função:", error);
              }
            }
            if (item?.id && item.props.set) {
              item?.props.set({ k: item.keyid || item.id, v: e._id });
            }
          },
          // if option is array or fuction exec
          item: item.option,
          update: item.update,
        }),

        errorMessage && {
          component: "text",
          text: errorMessage,
          style: {
            color: Theme.colors.error || "red",
            fontSize: "0.8rem",
            marginTop: "4px",
          },
        },
      ].filter(Boolean),
    };
  }
  // file
  else if (item.type === "file") {
    const errorMessage = item?.errorMessage || "";

    return {
      component: "container",
      style: {
        display: "flex",
        flexDirection: "column",
      },
      children: [
        FileLoad({
          value: item.value,
          onChange: async (e) => {
            if (item.func)
              try {
                e = item.func(e);
              } catch (error) {
                console.error("Erro ao executar a função:", error);
              }

            if (item.id && item.props?.set)
              item.props.set({ k: item.id, v: e });
          },
          title: item.title || "",
          label: item.label || "",
          style: {
            textResult: {
              fontSize: 14,
            },
            ...item.style,
          },
          set: (value) => {
            if (item.func)
              try {
                value = item.func(value);
              } catch (error) {
                console.error("Erro ao executar a função:", error);
              }

            if (item.id && item.props.set)
              item.props.set({ k: item.id, v: value });

            if (item.set)
              try {
                item.set(value);
              } catch (error) {
                console.error("Erro ao executar o set:", error);
              }
          },
        }),

        errorMessage && {
          component: "text",
          text: errorMessage,
          style: {
            color: Theme.colors.error || "red",
            fontSize: "0.8rem",
            marginTop: "4px",
          },
        },
      ].filter(Boolean),
    };
  }

  // input
  else if (item.type === "input") {
    if (!item.id) return;

    // Obter a mensagem de erro, se existir
    const errorMessage = item?.errorMessage || "";

    return {
      component: "container",
      style: {
        display: "flex",
        flexDirection: "column",
      },
      children: [
        Input({
          id: item?.id,
          value: item?.props?.value || item?.value || undefined,
          setValue: item?.set,
          title: item?.label || "",
          maxLength: item?.maxLength,
          mask: item?.mask || "",
          keyid: item?.keyid || item?.id,
          style: {
            ...item.style,
          },
          set: item?.props?.set,
          check: item?.check || { type: "string" },
          clear: item?.clear,
          disabled: item?.disabled,
          placeHolder: item?.props?.placeHolder || item?.placeHolder,
        }),

        errorMessage && {
          component: "text",
          text: errorMessage,
          style: {
            color: Theme.colors.error || "red",
            fontSize: "0.8rem",
            marginTop: "4px",
          },
        },
      ].filter(Boolean),
    };
    // } else if (item.type === "textArea") {
    // return TextArea({});
    //password
  } else if (item.type === "password") {
    if (!item.id) return;
    // if (!item.props?.value) item.props.value = "";
    return Input({
      id: item.id,
      value: item.props?.value || item.value || undefined,
      setValue: item.set,
      title: item.label || "",
      maxLength: item?.maxLength,
      mask: item.mask || "",
      keyid: item.keyid || item.id,
      type: "password",
      style: {
        flex: 1,
        ...item.style,
      },
      set: item.props?.set,
      check: item.check || { type: "string" },
      clear: item.clear,
      disabled: item?.disabled,
      placeHolder: item?.placeHolder,
    });
  }
  // switch
  else if (item.type === "switch") {
    return Switch({
      id: item.id,
      value: item.value || false,
      checked: item.checked || false,
      onChange: item.onChange,
      title: item.label || item.title || "",
      keyid: item.keyid || item.id,
      style: item.style,
    });
  }
  // list
  else if (item.type === "list") {
    if (item?.props?.value && item?.props?.value?.length > 0) {
      item.props.value = item?.props?.value?.filter(
        (item) => item !== null && item !== undefined
      );
    } else item.props.value = [];
    if (item.id) return BoxList(item, getElement);
  } else if (item.type === "inputCurrency") {
    return InputCurrency({
      value: item?.value,
      onChange: item?.set,
      placeHolder: item?.placeHolder,
      title: item?.title,
      style: {
        box: item?.style?.box,
        titile: item?.style?.title,
        item: item?.style?.item,
        input: item?.style?.input,
        coin: item?.style?.coin,
      },
    });
  }
  //  add botao

  return {
    component: "text",
    text: "Erro ao criar input",
  };
};

export default getElement;
