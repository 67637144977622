import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import api from "service";

import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Context } from "context/data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import Navbar from "components/web/navbar";

import ManagementIcon from "assets/images/menu/icon-management.svg";

import Arrow from "assets/images/menu/arrowDown.svg";

import Home from "pages/home";
import Provider from "pages/provider";
import Business from "pages/business";
import Consumer from "pages/consumer";
import Order from "pages/order";
import Marketing from "pages/marketing";
import Financial from "pages/financial";
import Management from "pages/management";

export default () => {
  const { Data, Set } = Context();
  const { GoInitPage, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [menuItem, setMenuItem] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [profilesLoading, setProfilesLoading] = useState(true);

  // console.log("Data", Data);

  useEffect(() => {
    if (Data.user.profile?.permission?.length > 0) {
      let profileMenu = [];
      Data.user.profile?.permission.map((menu) => {
        console.log(menu.id?.image);
        if (menu.access[0]?.value === true)
          profileMenu.push({
            title: menu.id?.title,
            page: menu.id?.page,
            children: menu.id?.subMenu,
            style: {
              iconBox: {
                marginLeft: 4,
              },
            },
            image: {
              src: menu.id?.image,
              srcArrow: menu.id?.subMenu?.length > 0 ? Arrow : null,
            },
          });
      });
      setMenuItem(profileMenu);
    } else if (Data.user?.profile)
      setMenuItem([
        {
          title: "Configurações",
          page: "configuracoes",
          style: {
            iconBox: {
              marginLeft: 4,
            },
          },
          image: {
            src: ManagementIcon,
            srcArrow: Arrow,
          },
          children: [
            {
              title: "Gerenciar usuários",
              page: "user-management",
            },
            {
              title: "Níveis de acesso",
              page: "access-level",
            },
          ],
        },
      ]);
  }, [Data]);

  let navBar = Navbar({
    item: menuItem.map((menu) => {
      return menu;
    }),
  });

  let Nav = {
    component: "container",
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    children: [navBar],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
  };

  const Pages = [
    Nav,
    ...Home(),
    ...Provider(),
    ...Business(),
    ...Consumer(),
    ...Order(),
    ...Marketing(),
    ...Financial(),
    ...Management(),
  ];

  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
