import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import Button from "components/web/button";
import AddView from "components/web/view/add";
import Input from "components/web/input";

//images
import EditManagement from "assets/images/edit-management.png";
import DeleteManagement from "assets/images/icon-delete.png";

//pages
import FormUser from "./formUser";
import UserEdit from "./editUser";

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [popUpEdit, setPopUpEdit] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  // Função para abrir o pop-up de edição
  const handleEditClick = async (info) => {
    setItem(info);
    setPopUpEdit(true);
    // console.log("verUser", info);
  };

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      maxHeight: 32,
      fontSize: "2vh",
    },
  };

  const [formData, setFormData] = useState({});

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "user-management" ? "flex" : "none",
    },
    children: [
      FormUser(popUp, setPopUp, item, formData, setFormData),
      UserEdit(popUpEdit, setPopUpEdit, item),
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "user-management",
            title: "Gerenciar usuários",
            buttonTopBar: {
              style: {
                button: {},
                label: {},
              },
              title: "Adicionar novo usuário",
              onPress: () => {
                setPopUp(true);
              },
              //adicionei novo botao
            },
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontWeight: "normal",
                      // flex: 1.2,
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 64,
                maxHeight: 64,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Nome",
                style: {
                  flex: "0.5",
                },
              },
              email: {
                title: "E-mail",
              },
              last_access: {
                key: [],
                title: "último acesso",
                style: {},
              },
              profile: {
                key: ["profile", "name"],
                title: "Nível de acceso",
              },
              edit: {
                title: "Ações",
                style: {
                  flex: "0",
                },
                item: [
                  {
                    type: "image",
                    src: EditManagement,
                    size: 28,
                    style: {
                      display: "flex",
                      border: "none",
                      backgroundColor: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    },
                    onClick: async (info) => {
                      setPopUpEdit(true);
                      setItem(info);
                      // console.log("verUser", info);
                    },
                  },
                  {
                    type: "image",
                    src: DeleteManagement,
                    size: 28,
                    style: {
                      display: "flex",
                      border: "none",
                      backgroundColor: "white",
                      alignItems: "center",
                      cursor: "pointer",
                    },
                    onClick: async (info) => {
                      PopupShow({
                        title: `Deseja excluir o usuário ${info.name}?`,
                        description: "",
                        cancelText: "cancelar",
                        confirmText: "deletar",
                        onCancel: async () => {},
                        onConfirm: async () => {
                          await api.management.delete_user({ id: info._id });
                          setUpdate("user-management");
                        },
                      });
                    },
                  },
                ],
              },
            },
            item: api.management.list_manager,
          }),
        ],
      },
    ],
  };
};
