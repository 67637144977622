import Theme from "theme";
import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  // const [component, setComponent] = useState({
  //   value: "",
  //   focus: false,
  //   error: false,
  //   ...props,
  // });

  // const [inputValue, setInputValue] = useState(false);

  return {
    component: "container",
    style: {
      display: "flex",
      width: "auto",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
      gap: 2,
      ...props.style,
    },
    // onClick: () => {
    //   setComponent({ ...component, focus: true });
    // },
    children: [
      {
        component: "text",
        text: props.title,
        style: {
          ...props?.style?.title,
        },
      },
      {
        render: (
          <Switch
            checked={props?.checked}
            value={props?.value}
            onChange={props?.onChange}
            size={"medium"}
            style={props?.style?.switch}
            // mudar cor se providetype 2
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: Theme.colors.primary,
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track.MuiSwitch-track.MuiSwitch-track":
                {
                  backgroundColor: Theme.colors.primary,
                },
            }}
          />
        ),
      },
    ],
  };
};
