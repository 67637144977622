import Theme from "theme";
import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Howl, Howler } from "howler";

import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//api
import api from "service";

//components
import Input from "components/web/input";
import Button from "components/web/button";

//images
import Logo from "assets/images/logo.png";
import DogWoman from "assets/images/iconDogEpeople.svg";
import ShieldCheck from "assets/images/ShieldCheck.svg";

import Loading from "components/web/loading";

//sounds
import DogAudio from "assets/sounds/dog.mp3";

export default ({ props, content, setContent, setLoadingSave }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { PageInfo } = ShowPage();
  const { Data, Set } = Context();

  useEffect(() => {
    if (PageInfo.show === "ResetPassword") {
      setToken(PageInfo?.param);
    }
  }, [PageInfo]);

  useEffect(() => {
    if (window.location.href.includes("admin")) {
      setUserType("admin");
    } else if (window.location.href.includes("provider")) {
      setUserType("provider");
    } else if (window.location.href.includes("business")) {
      setUserType("business");
    }
  }, []);

  const inputStyle = {
    input: {
      width: "100%",
      height: 44,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      padding: 4,
      fontSize: 18,
    },
    title: {
      fontFamily: "Poppins",
      fontSize: 14,
      color: "rgba(52, 64, 84, 1)",
    },
  };

  const inputPassword = Input({
    title: "Nova senha",
    type: "password",
    value: password,
    setValue: setPassword,
    style: {
      ...inputStyle,
    },
  });

  const inputConfirmPassword = Input({
    title: "Confirmar nova senha",
    type: "password",
    value: confirmPassword,
    setValue: setConfirmPassword,
    style: {
      ...inputStyle,
    },
  });

  const handleUpdatePassword = async () => {
    setErrorMessage(""); // Limpa mensagem de erro anterior
    if (!password || !confirmPassword) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem.");
      return;
    }

    try {
      setLoadingSave(true);
      const { data } = await api.auth.new_password({
        type: userType,
        token,
        password,
      });
      if (data?.status) {
        setContent("Login");
        setLoadingSave(false);
        window.history.replaceState({}, document.title, "/");
      } else {
        setLoadingSave(false);
        setErrorMessage(
          "Erro ao atualizar a senha. Por favor, tente mais tarde."
        );
      }
    } catch (error) {
      setLoadingSave(false);
      setErrorMessage(
        "Erro na atualização da senha. Por favor, tente mais tarde."
      );
    }
  };

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: content === "ResetPassword" ? "flex" : "none",
            backgroundColor: "white",
            width: "28vw",
            height: "auto",
            marginTop: 32,
            borderRadius: 4,
            padding: "4%",
            border: "1px solid #E6E6E6",
            flexDirection: "column",
            gap: 28,
          },
          children: [
            {
              component: "text",
              text: "Criar nova senha",
              style: {
                width: "100%",
                fontFamily: "Poppins",
                textAlign: "flex-start",
                fontWeight: "600",
                fontSize: 24,
                color: "#05332F",
              },
            },
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "auto",
                flexDirection: "column",
                gap: 12,
              },
              children: [inputPassword, inputConfirmPassword],
            },
            errorMessage && {
              component: "text",
              text: errorMessage,
              style: {
                color: "red",
                fontFamily: "Poppins",
                fontSize: 14,
              },
            },
            {
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                height: "auto",
              },
              children: [
                Button({
                  label: "Atualizar senha",
                  style: {
                    button: {
                      backgroundColor: "rgba(0, 166, 96, 1)",
                      width: "100%",
                      height: 44,
                      borderRadius: 6,
                      border: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "white",
                    },
                  },
                  onPress: handleUpdatePassword,
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
