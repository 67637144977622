import { useEffect, useState } from "react";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import CalendarView from "../calender";
import { Render } from "@8_dev/sjson-web";
import theme from "theme";
import api from "service";

export default function ({
  id,
  targetDate,
  mode,
  category,
  provider,
  onChange,
}) {
  const [scheduleHour, setScheduleHour] = useState([]);
  const [hourList, setHourList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [targetId, setTargetId] = useState(0);

  // const [date, setDate] = useState("");

  if (targetDate !== undefined) targetDate = new Date(targetDate);

  const [selectedDate, setSelectedDate] = useState(
    targetDate ? new Date(targetDate) : new Date()
  );

  const [orderDateCount, setOrderDateCount] = useState({});

  useEffect(() => {
    if (targetId !== id) {
      setHourList([]);
      setScheduleHour([]);
      setOrderDateCount([]);
      setDateList([]);
      setTargetId(id);
    } else {
      setTargetId(0);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (provider != undefined) {
        let { data } = await api.provider.categoryHour(
          category,
          format(selectedDate, "T", {
            locale: ptBR,
            timeZone: "America/Sao_Paulo",
          }),
          provider
        );

        if (data?.data) {
          setScheduleHour(data.data.openHours);
          setOrderDateCount(data.data.orderOpen);
        }
      }
    })();
  }, [selectedDate]);

  useEffect(() => {
    (async () => {
      if (onChange)
        try {
          onChange(dateList);
        } catch (e) {
          console.log("onChange error", e);
        }
    })();
  }, [dateList]);

  return {
    component: "container",
    style: {
      with: "100%",
      minWidth: "100%",
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // maxHeight: "50vh",
      maxWidth: "20%",
    },
    children: [
      // Select date
      {
        component: "container",
        style: {
          // backgroundColor: "#f0f",
          border: "1px solid #E0E0E0",
          borderRadius: 4,
          display: "flex",
          // gap: 500,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "space-between",
          padding: "20px",
          margin: "10px",
          width: "95%",
        },
        children: [
          // bcg click close
          {
            render: (
              <CalendarView
                id={id}
                target={new Date(targetDate)}
                onSelect={async (date) => {
                  let { data } = await api.provider.categoryHour(
                    category,
                    format(date, "T", {
                      locale: ptBR,
                      timeZone: "America/Sao_Paulo",
                    }),
                    provider
                  );
                  if (data?.data) {
                    setScheduleHour(data.data.openHours);
                    setOrderDateCount(data.data.orderOpen);
                  }
                }}
                onChange={async (date) => {
                  // targetDate = date;
                  setSelectedDate(date);
                }}
                dateCount={orderDateCount}
              />
            ),
          },
        ],
      },

      // Hours
      {
        component: "container",
        style: {
          flex: 1,
          // backgroundColor: "#f0f",
          border: "1px solid #E0E0E0",
          borderRadius: 4,
          display: "flex",
          // gap: 500,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "space-between",
          padding: "20px",
          margin: "10px",
          width: "95%",
          // maxWidth: 10,
        },
        children: [
          // bcg click close
          {
            render: (
              <div
                style={{
                  flexWrap: "wrap",
                  margin: "0 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxHeight: 300,
                  overflow: "auto",
                }}
              >
                {scheduleHour.map((hour, index) => {
                  const splitHour = hour.split(":");
                  // console.log(splitHour);
                  const dateHour = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate(),
                    parseInt(splitHour[0]),
                    parseInt(splitHour[1])
                  );

                  const hourCount =
                    orderDateCount[
                      format(selectedDate, "T", {
                        locale: ptBR,
                        timeZone: "America/Sao_Paulo",
                      })
                    ];

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        margin: 4,
                        padding: 2,
                        flex: 0.3,
                      }}
                    >
                      <button
                        onClick={async () => {
                          if (hourList.includes(dateHour.toString())) {
                            dateList.splice(dateList.indexOf(dateHour), 1);
                            hourList.splice(
                              hourList.indexOf(dateHour.toString()),
                              1
                            );

                            setDateList([...dateList]);
                            setHourList([...hourList]);
                          } else {
                            setDateList(dateList.concat([dateHour]));
                            setHourList(hourList.concat([dateHour.toString()]));
                          }
                        }}
                        key={index}
                        style={{
                          width: 100,
                          height: 48,
                          padding: 10,
                          border: hourList.includes(dateHour.toString())
                            ? "none"
                            : "1px solid #92E1DA",
                          borderRadius: 8,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 5,
                          color: hourList.includes(dateHour.toString())
                            ? "#000"
                            : "#011627",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          cursor: "pointer",
                          backgroundColor: hourList.includes(
                            dateHour.toString()
                          )
                            ? "#92E1DA"
                            : "transparent",
                        }}
                      >
                        {hour}
                      </button>

                      {/* // counter bal notify */}
                      {Render({
                        component: "container",
                        style: {
                          position: "absolute",
                          aspectRatio: "1/1",
                          backgroundColor: theme.colors.pink1,
                          borderRadius: 20,
                          display:
                            hourCount && hourCount[hour] ? "flex" : "none",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 25,
                          marginTop: "-1",
                        },
                        children: [
                          {
                            component: "text",
                            text:
                              hourCount && hourCount[hour]
                                ? hourCount[hour]
                                : 0,
                            style: {
                              color: "#fff",
                              fontSize: 14,
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                            },
                          },
                        ],
                      })}
                    </div>
                  );
                })}
              </div>
            ),
          },
        ],
      },

      //Select Dates
      {
        component: "container",
        style: {
          flex: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "space-between",
          padding: "10px",
          margin: "10px",
          width: "99%",
          maxWidth: "99%",
          maxHeight: 200,
          overflow: "auto",
        },

        children: [
          // bcg click close
          {
            render: (
              <div
                style={{
                  flexWrap: "wrap",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                {dateList.map((date, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        backgroundColor: "rgba(146, 225, 218, 0.6)",
                        padding: 4,
                        margin: 16,
                        borderRadius: 4,
                        borderWidth: 1,
                      }}
                    >
                      <div
                        key={index}
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 0,
                          backgroundColor: "transparent",
                          fontSize: 14,
                          fontFamily: "Poppins",
                        }}
                      >
                        {
                          <p>
                            {format(date, "dd/MM/yyyy - HH:mm", {
                              locale: ptBR,
                              timeZone: "America/Sao_Paulo",
                            })}
                          </p>
                        }
                      </div>
                      <button
                        onClick={() => {
                          dateList.splice(dateList.indexOf(date), 1);
                          hourList.splice(hourList.indexOf(date.toString()), 1);

                          setDateList([...dateList]);
                          setHourList([...hourList]);
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderWidth: 0,
                          backgroundColor: "transparent",
                          padding: 0,
                          margin: 4,
                          borderWidth: 2,
                          borderColor: "rgba(100,100,100,0.5)",
                          color: "rgba(50,50,50,0.8)",
                          fontSize: 11,
                          fontFamily: "Arial",
                          borderRadius: 16,
                          width: 20,
                          height: 20,
                        }}
                      >
                        {<p>X</p>}
                      </button>
                    </div>
                  );
                })}
              </div>
            ),
          },
        ],
      },
    ],
  };
}
