import Theme from "theme";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Context } from "context/data";
import LoadingGif from "assets/images/loading.gif";

export default (opt) => {
  const { Data } = Context();

  return {
    render: (
      <div
        style={{
          top: 0,
          left: 0,
          width: "100%",
          minWidth: "100%",
          height: "100%",
          maxHeight: "100%",
          position: "absolute",
          backgroundColor: Theme.colors.gray.background,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 999,
        }}
      >
        {Render({
          component: "container",
          style: {
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          },
          children: [
            {
              render: (
                <img
                  style={{
                    display: "flex",
                    aspectRatio: "1/1",
                    width: 42,
                  }}
                  src={LoadingGif}
                />
              ),
            },
            {
              render: (
                <img
                  style={{
                    display: "flex",
                    aspectRatio: "1/1",
                    width: 42,
                  }}
                  src={LoadingGif}
                />
              ),
            },
            {
              render: (
                <img
                  style={{
                    display: "flex",
                    aspectRatio: "1/1",
                    width: 42,
                  }}
                  src={LoadingGif}
                />
              ),
            },
          ],
        })}
      </div>
    ),
  };
};
