import Theme from "theme";
import Icon from "components/web/icon";
import CurrencyIcon from "assets/images/menu/icon-currency.svg";

export default (props) => {
  return {
    component: "container",
    style: {
      ...Theme.box.financeIndicator.main,
      ...props.style?.main,
    },
    children: [
      //Title + Icon
      {component: "container",
        style:{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height:"48px",
        },
        children:[
      //Text
      {
        component: "text",
        text: props.title,
        style: {
          ...Theme.box.financeIndicator.title,
          ...props.style?.title,
        },
      },
      //Icon
      Icon({
            size: "4vh",
            style: {
              iconBox: {
              width: "18%",
              ...props.style?.iconBox,
              },
            },
                src: CurrencyIcon,
          }),
        ],
      },
      //Number
      {
        component: "text",
        text: props.value,
        style: {
          ...Theme.box.financeIndicator.value,
          ...props.style?.value,
        },
      },
    ],
  };
};
